import React, { useEffect, useState, Fragment } from "react";
import ViewComponent from './canvasMapperView';
import { useDispatch, useSelector } from 'react-redux';
import { setFloorViewPosition, setCameraViewPosition, setCameraZoomScale, setZoomScale, setFloorplanmarkers, setCameramarkers, setloadCameraUrl, setCameraPositionMarker, setSelectedFloorMarker, setSelectedCameraMarker, setMaskShapes, setSelectedFloorTestPoint, setSelectedCameraTestPoint } from './../actions/imageData';
import { setSelectedShapeToDelete, SetImageMappingLoading, setSelectedShape } from './../actions/imageOptions';

const CustomReactPictureAnnotation = (props) => {
  console.log("Image Annotation rendered", props.id);
  const dispatch = useDispatch();
  //Get image urls from redux
  const imageUrls = useSelector(state => state.imageDataReducer.imageUrls);
  const floorplanmarkers = useSelector(state => state.imageDataReducer.floorplanmarkers);
  const cameramarkers = useSelector(state => state.imageDataReducer.cameramarkers);
  const loadCameraUrl = useSelector(state => state.imageDataReducer.loadCameraUrl);
  const addPointToggle = useSelector(state => state.imageOptionsReducer.addPointToggle);
  const selectAndMovePointToggle = useSelector(state => state.imageOptionsReducer.selectAndMovePointToggle);
  let currentCameraIndex = useSelector(state => state.imageDataReducer.currentCameraIndex);
  const cameraImageUrls = useSelector(state => state.imageDataReducer.cameraImageUrls);
  const homography = useSelector(state => state.imageDataReducer.homography);
  const plotGridLines = useSelector(state => state.imageOptionsReducer.plotGridLines);
  const addTestPointToggle = useSelector(state => state.imageOptionsReducer.addTestPointToggle);
  
  const floorPlanAPITestPoints = useSelector(state => state.imageDataReducer.floorPlanAPITestPoints);
  const cameraAPITestPoints = useSelector(state => state.imageDataReducer.cameraAPITestPoints);
  const floorPlanTestPoints = useSelector(state => state.imageDataReducer.floorPlanTestPoints);
  const cameraTestPoints = useSelector(state => state.imageDataReducer.cameraTestPoints);
  const editCameraPositionToggle = useSelector(state => state.imageOptionsReducer.editCameraPostionToggle);

  const invertFloorplanColors = useSelector(state => state.imageOptionsReducer.invertFloorplanColors);
  const changePointColors = useSelector(state => state.imageOptionsReducer.changePointColors);
  const cameraPositionMarker = useSelector(state => state.imageDataReducer.cameraPositionMarker);
  const showAllCameras = useSelector(state => state.imageOptionsReducer.showAllCameras);
  const zoomScale = useSelector(state => state.imageOptionsReducer.zoomScale);
  const cameraZoomScale = useSelector(state => state.imageOptionsReducer.cameraZoomScale);  
  const zoomInToggle = useSelector(state => state.imageOptionsReducer.zoomInToggle);
  const zoomOutToggle = useSelector(state => state.imageOptionsReducer.zoomOutToggle);
  const zoomCanvasId = useSelector(state => state.imageOptionsReducer.zoomCanvasId);
  const imageMappingLoading = useSelector(state => state.imageOptionsReducer.imageMappingLoading);
  const projectData = useSelector(state => state.imageDataReducer.project);
  const selectedShape = useSelector(state => state.imageOptionsReducer.selectedShape);
  const maskShapes = useSelector(state => state.imageDataReducer.maskShapes);
  const adjustViewPositionToggle = useSelector(state => state.imageOptionsReducer.adjustViewPositionToggle);
  const cameraAdjustViewPositionToggle = useSelector(state => state.imageOptionsReducer.cameraAdjustViewPositionToggle);
  const floorViewPosition = useSelector(state => state.imageOptionsReducer.floorViewPosition);
  const cameraViewPosition = useSelector(state => state.imageOptionsReducer.cameraViewPosition);

  //console.log("data.camera_mask --------",maskShapes);

  console.log("cameramarkers",cameramarkers);
  
  if(currentCameraIndex === undefined)
			currentCameraIndex = 0;

  // useEffect(() => {
  //   fetchData();
  // }, [props]);
  useEffect(()=>{
    localStorage.setItem("addPointToggle", addPointToggle);
    localStorage.setItem("addTestPointToggle", addTestPointToggle);
    }, [addPointToggle, !addTestPointToggle]);
  
  useEffect(() => {
    localStorage.setItem("addPointToggle", addPointToggle);
    localStorage.setItem("addTestPointToggle", addTestPointToggle);
    if(editCameraPositionToggle){
      localStorage.setItem("editCameraPositionToggle", editCameraPositionToggle);
    }
    return () => {
      localStorage.removeItem("addPointToggle");
      localStorage.removeItem("addTestPointToggle");
      localStorage.removeItem("editCameraPositionToggle");
    }
  }, [addPointToggle, addTestPointToggle, editCameraPositionToggle]);

  useEffect(() => {
    fetchData();
  },[]);

  const updateMarkers = (Markers, id) => {
    if (id === "Canvas") {
      dispatch(setFloorplanmarkers(Markers));
    } else if (id === "CameraCanvas") {
      console.log("FILTER: MARKERS Now This", Markers);
      dispatch(setCameramarkers(Markers));
    }    
  }

  const updateTestMarkers = (Markers, id) => {
    if (id === "Canvas") {
      dispatch(setFloorplanmarkers(Markers));
    } else if (id === "CameraCanvas") {
      dispatch(setCameramarkers(Markers));
    }    
  }

  const updateShapes = (Shapes) => {
    dispatch(setMaskShapes(Shapes));
    dispatch(setSelectedShape(""));
    // dispatch(setloadCameraUrl(true));
  }

  const setLoadingImageMapping = (flag) => {
    console.log("setLoadingImageMapping -- true", flag);
    dispatch(SetImageMappingLoading(flag));
  }

  const updateSelectedMask = (id) => {
    dispatch(setSelectedShapeToDelete(id));
    dispatch(setSelectedShape(""));
  }
  
  const updateSelectedMarker = (Marker, id) => {
    dispatch(setSelectedFloorTestPoint(""));
    dispatch(setSelectedCameraTestPoint(""));
    if (id === "Canvas") {
      dispatch(setSelectedFloorMarker(Marker));
      dispatch(setSelectedCameraMarker(""));
    } else if (id === "CameraCanvas") {
      dispatch(setSelectedCameraMarker(Marker));
      dispatch(setSelectedFloorMarker(""));
    }
    else{
      dispatch(setSelectedCameraMarker(""));
      dispatch(setSelectedFloorMarker(""));
    }
    props.setSelectedMarkers();
  }

  const setCameraPosition = (data)=>{
    dispatch(setCameraPositionMarker(data));
    dispatch(setloadCameraUrl(true));
  }

  const setAdjustedViewPosition = (id, coords) => {
    if(id === "Canvas"){
      dispatch(setFloorViewPosition(coords));      
    }
    else if (id === "CameraCanvas") {
      dispatch(setCameraViewPosition(coords));
    }
  }

  const updateSelectedTestMarker = (Marker, id) => {
    dispatch(setSelectedFloorMarker(""));
    dispatch(setSelectedCameraMarker(""));
    if (id === "Canvas") {
      dispatch(setSelectedFloorTestPoint(Marker));
      dispatch(setSelectedCameraTestPoint(""));
    } else if (id === "CameraCanvas") {
      dispatch(setSelectedCameraTestPoint(Marker));
      dispatch(setSelectedFloorTestPoint(""));
    }
    else{
      dispatch(setSelectedCameraTestPoint(""));
      dispatch(setSelectedFloorTestPoint(""));      
    }
    props.setSelectedTestMarkers();
  }

  const fetchData = () => {
    console.log("ViewComponent");
    console.log(props.id);
    if (props.id == "Canvas") {
      ViewComponent(imageUrls, props.id, addPointToggle, selectAndMovePointToggle, updateMarkers, floorplanmarkers, setCameraPosition, currentCameraIndex, cameraImageUrls, updateSelectedMarker, homography, plotGridLines, addTestPointToggle, props.addTestPointAPI,floorPlanAPITestPoints,floorPlanTestPoints, invertFloorplanColors, changePointColors, cameraPositionMarker, showAllCameras, zoomScale, cameraZoomScale, zoomInToggle, zoomOutToggle, zoomCanvasId , setLoadingImageMapping, imageMappingLoading, projectData, selectedShape, updateShapes, maskShapes, updateSelectedMask,editCameraPositionToggle, adjustViewPositionToggle, setAdjustedViewPosition, floorViewPosition, updateSelectedTestMarker, props.updateTestPointAPI);
    }
    else {
      ViewComponent(props.currentCameraUrl, props.id, addPointToggle, selectAndMovePointToggle, updateMarkers, cameramarkers, setCameraPosition, currentCameraIndex, cameraImageUrls, updateSelectedMarker, homography, plotGridLines, addTestPointToggle, props.addTestPointAPI,cameraAPITestPoints,cameraTestPoints, invertFloorplanColors, changePointColors, cameraPositionMarker, showAllCameras, zoomScale, cameraZoomScale, zoomInToggle, zoomOutToggle, zoomCanvasId, setLoadingImageMapping, imageMappingLoading, projectData, selectedShape, updateShapes, maskShapes, updateSelectedMask,editCameraPositionToggle, cameraAdjustViewPositionToggle, setAdjustedViewPosition, cameraViewPosition, updateSelectedTestMarker, props.updateTestPointAPI);
      dispatch(setloadCameraUrl(!loadCameraUrl));
    }
  }

 
  useEffect(() => {
    if ((loadCameraUrl)) {
      console.log(addTestPointToggle);    
      fetchData();
    }
  },[loadCameraUrl]);

  // Get the width and height for different screens
  const [canvasWidth, setcanvasWidth] = useState(637);
  const [canvasHeight, setCanvasHeight] = useState(637);
  useEffect(() => {
    let x = document.querySelector(".image-mapping-box").offsetWidth;
    let y = document.querySelector(".image-mapping-box").offsetHeight;
    setcanvasWidth(x);
    setCanvasHeight(y);
  }, [canvasWidth, canvasHeight]);

  return (
    <Fragment>      
      <canvas
        id={props.id}
        width={canvasWidth}
        height={canvasHeight}
        currentCameraUrl={props.currentCameraUrl}
        setSelectedMarkers = {props.setSelectedMarkers}
        setSelectedTestMarkers = {props.setSelectedTestMarkers}
        addTestPointAPI = {props.addTestPointAPI}
        updateTestPointAPI = {props.updateTestPointAPI}
      />
    </Fragment>
  )
};

export default CustomReactPictureAnnotation;
