import { InputGroup, FormControl } from 'react-bootstrap';
import BackBtn from './../helpers/backBtn/BackBtn';
import GreenBtnLg from '../helpers/greenBtnLg/GreenBtnLg';
import './CreateProject.css';
import CustomImageUploader from "./CustomImageUploaderComponent";
import { setCreateProjectData } from './../../actions/imageData';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { setImageUrls, setCameraImageUrls } from './../../actions/imageData';

function CreateProject() {
  const dispatch = useDispatch();
  const imageUrls = useSelector(state => state.imageDataReducer.imageUrls);
  const project = useSelector(state => state.imageDataReducer.project);
  const [singleImageError, setSingleImageError] = useState(false);
  const [invalidImageError, setInvalidImageError] = useState(false);
  const [imageFlag, setImageFlag] = useState(false);
  const history = useHistory();
  const [values, setValues] = useState({
    client_name: project.client_name,
    building_name: project.building_name,
    project_name: project.project_name,
    floorplanImg: imageUrls
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [clientValid, setClientValidMessage] = useState("");
  const [buildingValid, setBuildingValidMessage] = useState("");
  const [projectValid, setProjectValidMessage] = useState("");

  const handleBack = () => {
    history.push("/");
  }
  function useForceUpdate(){    
    return () => setImageFlag(imageFlag => true);
 // update the state to force render
  }
    // call your hook here
 useForceUpdate();
  const handleInputChange = (event) => {
    const target = event.target;
    const str = event.target.value;
    if (target.name == "client_name") {
      if (!str.match(/^[a-zA-Z0-9\s]+$/) || str.charAt(0) == " ") {
        setClientValidMessage("Required input field is invalid. Please use alphanumeric values only.");
      } else {
        setClientValidMessage("");
      }
    } else if (target.name == "building_name") {
      if (!str.match(/^[a-zA-Z0-9\s]+$/) || str.charAt(0) == " ") {
        setBuildingValidMessage("Required input field is invalid. Please use alphanumeric values only.");
      } else {
        setBuildingValidMessage("");
      }
    } else {
      if (!str.match(/^[a-zA-Z0-9\s]+$/) || str.charAt(0) == " ") {
        setProjectValidMessage("Required input field is invalid. Please use alphanumeric values only.");
      } else {
        setProjectValidMessage("");
      }
    }
    setValues({
      ...values,
      [target.name]: target.value
    });
  }

  const onSubmit = () => {
    let count = 0;
    for (let item in values) {
      if (values[item] == null) {
        setErrorMessage("Please fill all the input fields.")
        break;
      }
      else {
        count += 1;
      }
    }
    if ((count === 3 || count === 4) && !clientValid && !buildingValid && !projectValid) {
      if (!imageUrls) {
        setErrorMessage("Please upload the floor plan image.")
      } else if (imageUrls && !imageUrls.length) {
        setErrorMessage("Please upload the floor plan image.")
      } else {
        setErrorMessage("");
        dispatch(setCreateProjectData(values));
        history.push("/addScene");
      }
    }
  }

  const removeOldImage = () => {
    setValues({
      ...values,
      floorplanImg: []
    });
  }

  const handleDeleteImage = () => {
    setValues({
      ...values,
      floorplanImg: []
    });
    dispatch(setImageUrls([]));
    setImageFlag(false);
  }

  return (
    <div className="CreateProject">
      <div className="title">CREATE PROJECT</div>
      <div className="container">
        <h2 className="heading">Client Name</h2>
        <InputGroup className="mb-3 create-project-input">
          <FormControl
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            className="createProject-input"
            placeholder="Example: Taubman"
            value={values.client_name}
            name="client_name"
            onChange={handleInputChange}
            maxlength="50"
            required
            pattern="[a-zA-Z0-9\s]+"
            title="Three letter country code"
          />
        </InputGroup>
        {clientValid && <span className="errorMessage">{clientValid}</span>}
        <h2 className="heading">Site Name</h2>
        <InputGroup className="mb-3 create-project-input">
          <FormControl
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            className="createProject-input"
            placeholder="Example: Beverly Center"
            name="building_name"
            value={values.building_name}
            onChange={handleInputChange}
            maxlength="50"
            required
            pattern="[a-zA-Z0-9\s]+"
          />
        </InputGroup>
        {buildingValid && <span className="errorMessage">{buildingValid}</span>}
        <h2 className="heading">Project Title</h2>
        <p className="description">Usually references the floor plan</p>
        <InputGroup className="mb-3 create-project-input">
          <FormControl
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            className="createProject-input"
            placeholder="Example: Floor 6"
            name="project_name"
            value={values.project_name}
            onChange={handleInputChange}
            maxlength="50"
            required
            pattern="[a-zA-Z0-9\s]+"
          />
        </InputGroup>
        {projectValid && <span className="errorMessage">{projectValid}</span>}
        <h2 className="heading">Upload Floor Plan</h2>
        <p className="description">Floor Plan will be used for all scenes within the project (.png .jpg .jpeg)</p>

        <CustomImageUploader
          buttonText="UPLOAD"
          singleImage={true}
          singleCameraImage={false}
          setSingleImageError={setSingleImageError}
          setErrorMessage={setErrorMessage}
          setMultipleImageFlag = {setImageFlag}
          withPreview={true}
          removeOldImage = {removeOldImage}
          setInvalidImageError={setInvalidImageError}
        />


        {(imageUrls.length > 0 && values.floorplanImg.length) || imageFlag ?
          <div className="fileUploader ">
            <div className="fileContainer">
              <div className="uploadPicturesWrapper">
                <div className="uploadPicturesWrapper_inner_div">
                  <div className="uploadPictureContainer">
                    <div className="deleteImage" onClick={handleDeleteImage}>X</div>
                    <img src={imageUrls} className="uploadPicture" alt="preview" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          : ""
        }

        {errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {singleImageError && <p className="errorMessage">Invalid Request. Remove the old image and then try to add new image.</p>}
        {invalidImageError && <p className="errorMessage">Please upload the valid image. (.png .jpg .jpeg) images only allowed.</p>}

        <div className="navigation-buttons">
          <BackBtn label="BACK" customClickEvent={handleBack} />
          <GreenBtnLg label="NEXT" customClickEvent={onSubmit} />
        </div>
      </div>
    </div>
  );
}

export default CreateProject;