import floorPlanPointer from './../assets/images/markerPoint-floorPlan.svg';
import cameraViewPointer from './../assets/images/markerPoint-cameraView.svg';
import redCameraMarker from './../assets/images/markerCamera-scene-red.svg';
import blueCameraMarker from './../assets/images/markerCamera-scene-blue.svg';
import crossHair from './../assets/images/cursor-crosshairs.svg';
import cursorMove from './../assets/images/cursor-move.svg';
import pointMarkerTest from './../assets/images/pointMarker-test.svg';
import pointMarkerDelete from './../assets/images/pointMarker-Delete.svg';
import adjView from './../assets/icons/icon-viewPosition-dark.svg';

import { fabric } from "fabric";

export default function ViewComponent(imageUrl, id, addPointToggle, selectAndMovePointToggle,  updateMarkers, markerPointers, setCameraPosition, currentCameraIndex, cameraUrls, updateSelectedMarker, homography, plotGridLines, addTestPointToggle, addTestPointAPI, testPoints,addedTestPoint, invertFloorplanColors, changePointColors, cameraPositionMarker, showAllCameras, zoomScale, cameraZoomScale, zoomInToggle, zoomOutToggle, zoomCanvasId, setLoadingImageMapping, imageMappingLoading, projectData, selectedShape, updateShapes, maskShapes, updateSelectedMask, editCameraPositionToggle, adjustViewPositionToggle, setAdjustedViewPosition, viewPosition, updateSelectedTestMarker,updateTestPointAPI, generateHomography, generate_tps) {
  setLoadingImageMapping(true);
  let x = document.querySelector(".image-mapping-box").offsetWidth;
  let y = document.querySelector(".image-mapping-box").offsetHeight;
  let Markers = [];
  let loadedMarkers = [];
  let testMarkers = [];
  let cameraMarkers = [];
  // let apiTestMarkers = [];
  // let shapes = [];

  if(cameraPositionMarker){
    for(let cm = 0 ; cm< cameraUrls.length; cm++){      
      if(cameraPositionMarker[cm] && cameraPositionMarker[cm].length ){
        cameraMarkers[cm] = cameraPositionMarker[cm];
      }        
    }
  }
  else{
    cameraMarkers[currentCameraIndex] = [];
  }
  if(projectData && projectData !== undefined && projectData.cameraData && projectData.cameraData != undefined && markerPointers !== undefined && markerPointers.length === 0){
    for(let pd = 0 ; pd< cameraUrls.length; pd++){
      if(projectData.cameraData[pd] && projectData.cameraData[pd]['world_points'] && id === "Canvas"){       
        loadedMarkers[pd] = projectData.cameraData[pd]['world_points'];       
      }
      else if( projectData.cameraData[pd] && projectData.cameraData[pd]['camera_points'] && id === "CameraCanvas"){
        console.log("Loading Camera Points!");
        loadedMarkers[pd] = projectData.cameraData[pd]['camera_points'];
      }
    }    
  }

  if(markerPointers){
    for(let mp = 0 ; mp< cameraUrls.length; mp++){
      if(markerPointers[mp] && markerPointers[mp].length ){
        Markers[mp] = markerPointers[mp];
      }
    }    
  }
  else{
    Markers[currentCameraIndex] = [];
  }

  if(addedTestPoint && addedTestPoint !== undefined && addedTestPoint[currentCameraIndex] !== undefined){
    for(var tp = 0; tp < cameraUrls.length; tp++){
      if(addedTestPoint[tp]){
        var testPointLength = addedTestPoint[tp].length;
        let addedPoints = addedTestPoint[tp];
        if(addedPoints && testPointLength){
          testMarkers[tp] = addedPoints;
        }
      }
    }
  }
  // let i = 0;
  let floorPlanPointerWidth = 0;
  let floorPlanPointerHeight = 0;
  let markerObj, imageObj ;
  let cameraMarkerWidth = 0;
  let cameraMarkerHeight = 0;
  var panning, isMoved = false;
  let dragTarget;

  function removeAllChildNodes(parent) {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
      }
  }
  if(id === "Canvas"){  // Important - FloorPlan section   
    const container = document.querySelector('.floorPlanSection');
    if(container){
      removeAllChildNodes(container);
      var floorCanvasID = document.createElement('canvas');
      floorCanvasID.setAttribute('id', id);
      floorCanvasID.setAttribute('width', x);
      floorCanvasID.setAttribute('height', y);
      container.appendChild(floorCanvasID);
      var cursorImg = document.createElement('img');
      cursorImg.setAttribute('class', 'cursor');
      cursorImg.setAttribute('id', 'floorCursor');
      if(selectAndMovePointToggle === true) {
        cursorImg.setAttribute('src', cursorMove);
      } else if (adjustViewPositionToggle) {
        cursorImg.setAttribute('src', adjView);
      } else {
        cursorImg.setAttribute('src', crossHair);
      }
      container.appendChild(cursorImg);
    }
  }

  if(id === "CameraCanvas"){ // Important - Camera section    
    const CameraContainer = document.querySelector('.cameraCanvasSection');
    if(CameraContainer){
      removeAllChildNodes(CameraContainer);
      var canvasID = document.createElement('canvas');
      canvasID.setAttribute('id', "CameraCanvas");
      canvasID.setAttribute('width', x);
      canvasID.setAttribute('height', y);
      CameraContainer.appendChild(canvasID);
      var cameraCursorImg = document.createElement('img');
      cameraCursorImg.setAttribute('class', 'cursor');      
      cameraCursorImg.setAttribute('id', 'cameraCursor');    
      if(selectAndMovePointToggle === true) {
        cameraCursorImg.setAttribute('src', cursorMove);
      } else if (adjustViewPositionToggle){
        cameraCursorImg.setAttribute('src', adjView);
      } else {
        cameraCursorImg.setAttribute('src', crossHair);
      }
      CameraContainer.appendChild(cameraCursorImg);
    }
  }

  
  var canvas = new fabric.Canvas(id,{preserveObjectStacking:true}); // IMPORTANT attribute - {preserveObjectStacking:true}
  canvas.stateful = true;
  canvas.clear();
  canvas.selection = false;

  fabric.Image.fromURL(cameraViewPointer, function(img) {
    floorPlanPointerWidth =  img.width;
    floorPlanPointerHeight = img.height;
  }); 

  fabric.Image.fromURL(redCameraMarker, function(img) {
    cameraMarkerWidth =  img.width;
    cameraMarkerHeight = img.height;
  }); 

  if(homography && homography !== undefined &&  homography[currentCameraIndex] !== undefined && plotGridLines ){
    setTimeout(function(){
      fabric.Image.fromURL(imageUrl, function(img) {
        let imageTextureSize = img.width > img.height ? img.width : img.height;
        if (imageTextureSize > fabric.textureSize) {
              fabric.textureSize = imageTextureSize;
        }
        imageObj = img.set({
                          scaleX: canvas.width / img.width,
                          scaleY: canvas.height / img.height,
                          id: id
                        });
        
        imageObj.lockMovementX = true;
        imageObj.lockMovementY = true;
        imageObj.hasBorders = false;
        imageObj.selectable = false;
        canvas.add(imageObj);
        canvas.renderAll();

        if(id == "Canvas" && invertFloorplanColors){
          imageObj.applyFilters();
          canvas.requestRenderAll(); 
          imageObj.applyFilters([ new fabric.Image.filters.Contrast({ contrast: 15 })]);
        }
        
        setZoomScaling(true);
        if(!Markers[currentCameraIndex] || Markers[currentCameraIndex] === undefined){
          setLoadingImageMapping(false);
        }
        
      });
    }, 1000);
  }
  else{
    fabric.Image.fromURL(imageUrl, function(img) {
      let imageTextureSize = img.width > img.height ? img.width : img.height;
      if (imageTextureSize > fabric.textureSize) {
            fabric.textureSize = imageTextureSize;
      }
      imageObj = img.set({
                        scaleX: canvas.width / img.width,
                        scaleY: canvas.height / img.height,
                        id: id
                      });

      imageObj.lockMovementX = true;
      imageObj.lockMovementY = true;
      imageObj.hasBorders = false;
      imageObj.selectable = false;
      canvas.add(imageObj);
      canvas.renderAll();

      if(id == "Canvas" && invertFloorplanColors){
        imageObj.applyFilters();
        canvas.requestRenderAll(); 
        imageObj.applyFilters([ new fabric.Image.filters.Contrast({ contrast: 15 })]);
      }

      setZoomScaling(false);
      if(!Markers[currentCameraIndex] || Markers[currentCameraIndex] === undefined){
        setLoadingImageMapping(false);
      }
      if(loadedMarkers && loadedMarkers.length !== 0 && markerPointers !== undefined && markerPointers.length === 0){
        setLoadedInitialMarkers();
        updateMarkers(Markers, id);
      }      
    });
  }

  var AdjustedLeft;
  var AdjustedTop;

  function setAdjustViewPosition(){ // Adjust View position Snap back Fix

    console.log("FILTER: Adjusting view position....");

    if(viewPosition === "" || viewPosition === undefined){
      return;
    }

    console.log(viewPosition);
    var scale;
    if(zoom > 1){
      scale =  1 / zoom;
    }
    else{
      scale =  1 * zoom;
    }

    AdjustedLeft = viewPosition.tl.lerp(viewPosition.br).x;
    AdjustedTop = viewPosition.tl.lerp(viewPosition.br).y;

    if(zoom !== 1){
      if(scale > 1){
        AdjustedLeft = viewPosition.tl.lerp(viewPosition.br).x / scale;
        AdjustedTop = viewPosition.tl.lerp(viewPosition.br).y / scale;
      }
      else
      {
        AdjustedLeft = viewPosition.tl.lerp(viewPosition.br).x * scale;
        AdjustedTop = viewPosition.tl.lerp(viewPosition.br).y * scale;
      }
    }  

    console.log("AdjustedLeft",AdjustedLeft)
    console.log("AdjustedTop",AdjustedTop)
    imageObj.set({
      left: AdjustedLeft,
      top: AdjustedTop,
      originX: 'center',
      originY: 'center',
    });
    canvas.renderAll();
  }

  function addTestPoints(options) { // Adding Testpoints on image
    console.log("FILTER: Adding test points....");

    var p = canvas.getPointer(options.e);
    var mouseXPos = (p.x < 0 ) ? 0.00 : p.x;
    var mouseYPos = (p.y < 0 ) ? 0.00 : p.y;
    var containerHeight = canvas.height;
    var imgHeight = imageObj.height;

    //mouseYPos = imgHeight - p.y;
    // Scaling in fabric
    var imgScaleX = imageObj.scaleX;
    var imgScaleY = imageObj.scaleY;
    var actualX ;
    var actualY ;

    if(imgHeight > containerHeight){
      if(imgScaleX < 1){
        actualX =  mouseXPos / imgScaleX;
      }
      else{
        actualX =  mouseXPos * imgScaleX;      
      }

      if(imgScaleY < 1){
        actualY =   mouseYPos / imgScaleY;
        containerHeight = containerHeight / imgScaleY;
      }
      else {
        actualY = mouseYPos * imgScaleY;
        containerHeight = containerHeight * imgScaleY;
      }
    }
    else{
      if(imgScaleX < 1){
        actualX =  mouseXPos * imgScaleX;
      }
      else{
        actualX =  mouseXPos / imgScaleX;      
      }

      if(imgScaleY < 1){
        actualY =   mouseYPos * imgScaleY;
        containerHeight = containerHeight * imgScaleY;
      }
      else {
        actualY = mouseYPos / imgScaleY;
        containerHeight = containerHeight / imgScaleY;
      }
    }

    console.log("FILTER: ID IS", id, ":", actualY, ":", mouseYPos);
    actualY = id == "CameraCanvas" ? imgHeight - actualY : actualY;

    if(imgHeight > containerHeight){
      actualY =  options.target.id == "CameraCanvas" ? actualY : Math.abs(containerHeight - actualY);
      //actualY = Math.abs(containerHeight - actualY);
    }
    else{
      //actualY =  Math.abs(imgHeight - actualY);
      actualY = options.target.id == "CameraCanvas" ? actualY : Math.abs(imgHeight - actualY);
    }
    actualY = (id == "Canvas") ? imgHeight - actualY: actualY;

    if(viewPosition !== "" && viewPosition !== undefined){
      console.log("viewPosition", viewPosition);
     
      var left = viewPosition.tl.x / imgScaleX ;
      var top = viewPosition.tl.y / imgScaleY;

      var scale = 1;
      if(zoom > 1){
        scale =  1 / zoom;
      }
      else{
        scale =  1 * zoom;
      }

      if(zoom !== 1){
        if(scale > 1){
          left = left / scale;
          top = top / scale;
        }
        else
        {
          left = left * scale;
          top = top * scale;
        }
      }

      actualX -= left;
      actualY += Math.abs(top);
    }
   
    var dataID = "";
    if((lastDraggedObject && (LastDragTargetXPos !== mouseXPos || LastDragTargetYPos !== mouseYPos))){ // TODO condition
      lastDraggedObject = null;
      return;
    }
    maintainTestPointMarkerSize(mouseXPos, mouseYPos, dataID , "test-point", "", eventFlag);      
    let testMarker = [];
    testMarker['Mouse_XPos'] = mouseXPos;
    testMarker['Mouse_YPos'] = mouseYPos;
    testMarker['XPos'] = actualX;
    if(imgHeight > containerHeight){
      testMarker['YPos'] =  Math.abs(containerHeight - actualY);
    }
    else{
      testMarker['YPos'] =  Math.abs(imgHeight - actualY);
    }

    var test_point_type;
    if(id === "Canvas") test_point_type = "camera";
    else test_point_type = "world";       

    let testPoint = [];
    testPoint[0] = actualX;
    //testPoint[1] = (id !== "Canvas") ? Math.abs(actualY - imgHeight) : testPoint[1];
    if(imgHeight > containerHeight){
      testPoint[1] =  Math.abs(containerHeight - actualY);
    }
    else{
      testPoint[1] =  Math.abs(imgHeight - actualY);
    }

    console.log("FILTER: ADD TEST POINT API", testPoint, ": ID", id);
    addTestPointAPI(testPoint, test_point_type, testMarker);
  }

  function addCameraMarkers(options) { // Adding Markers on image

    console.log("FILTER: Adding camera markers....");

    var p = canvas.getPointer(options.e);
    var mouseXPos = (p.x < 0 ) ? 0.00 : p.x;
    var mouseYPos = (p.y < 0 ) ? 0.00 : p.y;
    let containerHeight = canvas.height;
    let imgHeight = imageObj.height;

    // Scaling in fabric
    let imgScaleX = imageObj.scaleX;
    let imgScaleY = imageObj.scaleY;
    let actualX ;
    let actualY ;
   
    if(imgHeight > containerHeight){
      if(imgScaleX < 1){
        actualX =  mouseXPos / imgScaleX;
      }
      else{
        actualX =  mouseXPos * imgScaleX;      
      }

      if(imgScaleY < 1){
        actualY =   mouseYPos / imgScaleY;
        containerHeight = containerHeight / imgScaleY;
      }
      else {
        actualY = mouseYPos * imgScaleY;
        containerHeight = containerHeight * imgScaleY;
      }
    }
    else{
      if(imgScaleX < 1){
        actualX =  mouseXPos * imgScaleX;
      }
      else{
        actualX =  mouseXPos / imgScaleX;      
      }

      if(imgScaleY < 1){
        actualY =   mouseYPos * imgScaleY;
        containerHeight = containerHeight * imgScaleY;
      }
      else {
        actualY = mouseYPos / imgScaleY;
        containerHeight = containerHeight / imgScaleY;
      }
    }

    if(Markers && Markers[currentCameraIndex]){
      var idNum = Markers[currentCameraIndex].length;
    }
    else{
      var idNum = 0;
    }

    if(viewPosition !== "" && viewPosition !== undefined){
      console.log("viewPosition", viewPosition);
     
      var left = viewPosition.tl.x / imgScaleX ;
      var top = viewPosition.tl.y / imgScaleY;

      var scale = 1;
      if(zoom > 1){
        scale =  1 / zoom;
      }
      else{
        scale =  1 * zoom;
      }

      if(zoom !== 1){
        if(scale > 1){
          left = left / scale;
          top = top / scale;
        }
        else
        {
          left = left * scale;
          top = top * scale;
        }
      }

      actualX -= left;
      actualY += Math.abs(top);
    }
    console.log("FILTER: editing camera position: ", options.target.id, "actualY", actualY);
    if(imgHeight > containerHeight){
      //actualY =  Math.abs(containerHeight - actualY);
      actualY =  options.target.id == "CameraCanvas" ? actualY : Math.abs(containerHeight - actualY);

    }
    else{
     //actualY =  Math.abs(imgHeight - actualY);
      actualY = options.target.id == "CameraCanvas" ? actualY : Math.abs(imgHeight - actualY);
    }
    //Save camera marker position
    let camMarker = [];
    camMarker['Mouse_XPos'] = mouseXPos;
    camMarker['Mouse_YPos'] = mouseYPos;
    camMarker['XPos'] = actualX;
    camMarker['YPos'] = actualY;

    cameraMarkers[currentCameraIndex] = []
    cameraMarkers[currentCameraIndex][0] = camMarker;
    setCameraPosition(cameraMarkers);
    maintainCameraMarkerSize(actualX, actualY, redCameraMarker);
  }

  function addMarkers(options) { // Adding Markers on image

    console.log("FILTER: Adding markers....");

    var p = canvas.getPointer(options.e);
    var mouseXPos = (p.x < 0 ) ? 0.00 : p.x;
    var mouseYPos = (p.y < 0 ) ? 0.00 : p.y;
    let containerHeight = canvas.height;
    let imgHeight = imageObj.height;

    // Scaling in fabric
    let imgScaleX = imageObj.scaleX;
    let imgScaleY = imageObj.scaleY;
    let actualX ;
    let actualY ;
   
    if(imgHeight > containerHeight){
      if(imgScaleX < 1){
        actualX =  mouseXPos / imgScaleX;
      }
      else{
        actualX =  mouseXPos * imgScaleX;      
      }

      if(imgScaleY < 1){
        actualY =   mouseYPos / imgScaleY;
        containerHeight = containerHeight / imgScaleY;
      }
      else {
        actualY = mouseYPos * imgScaleY;
        containerHeight = containerHeight * imgScaleY;
      }
    }
    else{
      if(imgScaleX < 1){
        actualX =  mouseXPos * imgScaleX;
      }
      else{
        actualX =  mouseXPos / imgScaleX;      
      }

      if(imgScaleY < 1){
        actualY =   mouseYPos * imgScaleY;
        containerHeight = containerHeight * imgScaleY;
      }
      else {
        actualY = mouseYPos / imgScaleY;
        containerHeight = containerHeight / imgScaleY;
      }
    }

    if(Markers && Markers[currentCameraIndex]){
      var idNum = Markers[currentCameraIndex].length;
    }
    else{
      var idNum = 0;
    }

    if(viewPosition !== "" && viewPosition !== undefined){
      console.log("viewPosition", viewPosition);
     
      var left = viewPosition.tl.x / imgScaleX ;
      var top = viewPosition.tl.y / imgScaleY;

      var scale = 1;
      if(zoom > 1){
        scale =  1 / zoom;
      }
      else{
        scale =  1 * zoom;
      }

      if(zoom !== 1){
        if(scale > 1){
          left = left / scale;
          top = top / scale;
        }
        else
        {
          left = left * scale;
          top = top * scale;
        }
      }

      actualX -= left;
      actualY += Math.abs(top);
    }

    var marker = Array();

    marker['Mouse_XPos'] = mouseXPos;
    marker['Mouse_YPos'] = mouseYPos;
    marker['XPos'] = actualX;

    const addP = localStorage?.getItem("addPointToggle");
    const addT = localStorage?.getItem("addTestPointToggle");
    if (addP === "false" && addT === "false") {
      return;
    }

    console.log("FILTER: editing camera position: ", options.target.id, "actualY", actualY);

    if(imgHeight > containerHeight){
      marker['YPos'] =  !(options.target.id == "CameraCanvas") ? Math.abs(containerHeight - actualY) : actualY;
    }
    else{
      marker['YPos'] =  !(options.target.id == "CameraCanvas") ? Math.abs(imgHeight - actualY) : actualY;
    }

    console.log("marker", marker);
    
    if(!addPointToggle && !addTestPointToggle){
      return;
    }
    if(addTestPointToggle && !generateHomography && !generate_tps){
      return;
    }

    if(Markers[currentCameraIndex] !== undefined){
      Markers[currentCameraIndex].push(marker);
    }
    else{
      Markers[currentCameraIndex] = []
      Markers[currentCameraIndex][0] = marker;
    }      

    updateMarkers(Markers, id);
    maintainMarkerSize(mouseXPos, mouseYPos, idNum);
  }

  function getShapeCoordinates(shapeObj) {
    console.log("FILTER: Getting shape coordinates....");

    if(shapeObj && shapeObj !== undefined){
      var xyValues = shapeObj.aCoords;

      let containerHeight = canvas.height;
      let imgHeight = imageObj.height;

      let imgScaleX = imageObj.scaleX;
      let imgScaleY = imageObj.scaleY;

      let top_left_x = xyValues.tl.x ;
      let top_left_y = xyValues.tl.y ;
      let top_right_x = xyValues.tr.x ;
      let top_right_y = xyValues.tr.y ;
      let bottom_left_x = xyValues.bl.x ;
      let bottom_left_y = xyValues.bl.y ;
      let bottom_right_x = xyValues.br.x ;
      let bottom_right_y = xyValues.br.y;
    
      if(imgHeight > containerHeight){
        if(imgScaleX < 1){
          top_left_x = top_left_x / imgScaleX;        
          top_right_x = top_right_x / imgScaleX;        
          bottom_left_x = bottom_left_x / imgScaleX;
          bottom_right_x = bottom_right_x / imgScaleX;
          
        }
        else{
          top_left_x = top_left_x * imgScaleX;        
          top_right_x = top_right_x * imgScaleX;        
          bottom_left_x = bottom_left_x * imgScaleX;
          bottom_right_x = bottom_right_x * imgScaleX;   
        }

        if(imgScaleY < 1){
          top_left_y = top_left_y / imgScaleY;
          top_right_y = top_right_y / imgScaleY;
          bottom_left_y = bottom_left_y / imgScaleY;
          bottom_right_y = bottom_right_y / imgScaleY;

          containerHeight = containerHeight / imgScaleY;
        }
        else {
          top_left_y = top_left_y * imgScaleY;
          top_right_y = top_right_y * imgScaleY;
          bottom_left_y = bottom_left_y * imgScaleY;
          bottom_right_y = bottom_right_y * imgScaleY;

          containerHeight = containerHeight * imgScaleY;
        }
      }
      else{
        if(imgScaleX < 1){
          top_left_x = top_left_x * imgScaleX;        
          top_right_x = top_right_x * imgScaleX;        
          bottom_left_x = bottom_left_x * imgScaleX;
          bottom_right_x = bottom_right_x * imgScaleX;   
        }
        else{
          top_left_x = top_left_x / imgScaleX;        
          top_right_x = top_right_x / imgScaleX;        
          bottom_left_x = bottom_left_x / imgScaleX;
          bottom_right_x = bottom_right_x / imgScaleX;     
        }

        if(imgScaleY < 1){
          top_left_y = top_left_y * imgScaleY;
          top_right_y = top_right_y * imgScaleY;
          bottom_left_y = bottom_left_y * imgScaleY;
          bottom_right_y = bottom_right_y * imgScaleY;

          containerHeight = containerHeight * imgScaleY;
        }
        else {
          top_left_y = top_left_y / imgScaleY;
          top_right_y = top_right_y / imgScaleY;
          bottom_left_y = bottom_left_y / imgScaleY;
          bottom_right_y = bottom_right_y / imgScaleY;

          containerHeight = containerHeight / imgScaleY;
        }
      }    

      
    if(viewPosition !== "" && viewPosition !== undefined){
      console.log("viewPosition", viewPosition);
     
      var left = viewPosition.tl.x / imgScaleX ;
      var top = viewPosition.tl.y / imgScaleY;

      var scale = 1;
      if(zoom > 1){
        scale =  1 / zoom;
      }
      else{
        scale =  1 * zoom;
      }

      if(zoom !== 1){
        if(scale > 1){
          left = left / scale;
          top = top / scale;
        }
        else
        {
          left = left * scale;
          top = top * scale;
        }
      }

      top_left_x  -= left;
      top_left_y += Math.abs(top);
      top_right_x  -= left;
      top_right_y += Math.abs(top);
      bottom_left_x  -= left;
      bottom_left_y += Math.abs(top);
      bottom_right_x -= left;
      bottom_right_y += Math.abs(top);
    }

    if(imgHeight > containerHeight){
      top_left_y = Math.abs(containerHeight - top_left_y);
      top_right_y = Math.abs(containerHeight - top_right_y);
      bottom_left_y = Math.abs(containerHeight - bottom_left_y);
      bottom_right_y = Math.abs(containerHeight - bottom_right_y);
    }
    else{
      top_left_y = Math.abs(imgHeight - top_left_y);
      top_right_y = Math.abs(imgHeight - top_right_y);
      bottom_left_y = Math.abs(imgHeight - bottom_left_y);
      bottom_right_y = Math.abs(imgHeight - bottom_right_y);
    }

      var coords = [];   
      coords.push([top_left_x, top_left_y]); // Top left
      coords.push([top_right_x, top_right_y]); // Top right
      coords.push([bottom_left_x, bottom_left_y]); // Bottom right
      coords.push([bottom_right_x, bottom_right_y]); // Bottom left
      return coords;
    }
  }

  var rectangle ; 
  canvas.on('mouse:up', function(options) {
    let target = options.target;
    if(!target || target === undefined){
      return; // important
    }

    if (target.id === 'dummy'){
      canvas.remove(options.target);
      updateSelectedMarker("","");
      updateSelectedTestMarker("", "");
      return;
    }

    if(target && target.type === "image"  && !adjustViewPositionToggle && isMoved){
      return; // important - Adjust View position fix
    }
    if(!selectAndMovePointToggle && lastDraggedObject && lastDraggedObject !== undefined && lastDraggedObject._objects.length === 2 && lastDraggedObject.id === target.id && target.id !== "test-point"){       
      return;
    }

    if(maskShapes && maskShapes[currentCameraIndex] && target && target.id === "mask" && (target !== null && target._cacheCanvas !== null)) {
      var cCoords = getCoords(imageObj);
      var inBounds = insideBoundry({ x: target.aCoords.br.x , y: target.aCoords.br.y }, cCoords);
      if(inBounds) {
        target.setCoords();
        target.saveState();
        canvas.getActiveObject().setCoords();
        var xyValues = getShapeCoordinates(target);     
        maskShapes[currentCameraIndex][target.dataID] = xyValues; // TODO Mask Move
        updateShapes(maskShapes);
        updateSelectedMask(target.dataID);  
      } else {
        target.left = target._stateProperties.left;
        target.top = target._stateProperties.top;
        return;
      }
      return;
    }    

    if(target && target.type === "image"  && adjustViewPositionToggle && isMoved){
      setAdjustedViewPosition(target.id, imageObj.lineCoords);
    }
    
    panning = false;
    var p = canvas.getPointer(options.e);
    var mouseXPos = (p.x < 0 ) ? 0.00 : p.x;
    var mouseYPos = (p.y < 0 ) ? 0.00 : p.y;  

    if(lastDraggedObject && lastDraggedObject !== undefined && lastDraggedObject._objects.length === 2 && lastDraggedObject.id === target.id && target.id !== "test-point" && target.id !== "api-test-point"){
      if(LastDragTargetXPos !== mouseXPos || LastDragTargetYPos !== mouseYPos){
        var iconWidth =  33; // Important fix
        var iconHeight = 30;

        var scale = 1;
        if(zoom > 1){
          scale =  1 / zoom;
        }
        else{
          scale =  1 * zoom;
        }
        if(zoom !== 1){
          if(scale > 1){
            iconWidth = iconWidth / scale;
            iconHeight = iconHeight / scale;
          }
          else
          {
            iconWidth = iconWidth * scale;
            iconHeight = iconHeight * scale;
          }
        }
        mouseXPos = target.aCoords.tr.x - iconWidth ;
        mouseYPos = target.aCoords.tr.y + iconHeight;
        //mouseYPos = id != "CameraCanvas" ? target.aCoords.tr.y + iconHeight : (imageObj.height - target.aCoords.tr.y) + iconHeight;
        console.log("FILTER: MOVING..................", id, mouseYPos,  target.aCoords.tr.y)
       
      }
    }

    // Point selected
    if((LastDragTargetXPos === mouseXPos) && (LastDragTargetYPos === mouseYPos) && selectAndMovePointToggle && lastDraggedObject && lastDraggedObject !== undefined && lastDraggedObject._objects.length === 2 && lastDraggedObject.id === target.id && target.id !== "test-point"){
      var objs = canvas.getObjects();
      if (objs.length) {
        objs.forEach(function(e) {
          if (e && e.id === 'dummy'){
            canvas.remove(e);
          }
          if (e && e.type === 'group' && e.id === dragTarget.id) {
            highlightSelectedMarker(e)
          }
        });
      }
    }

    // test Point selected
    if((LastDragTargetXPos === mouseXPos) && (LastDragTargetYPos === mouseYPos) && selectAndMovePointToggle && lastDraggedObject && lastDraggedObject !== undefined && lastDraggedObject._objects.length === 2 && lastDraggedObject.id === target.id && target.id === "test-point"){
      var objs = canvas.getObjects();
      if (objs.length) {
        objs.forEach(function(e) {
          if (e && e.id === 'dummy'){
            canvas.remove(e);
          }
          if (e && e.type === 'group' && e.dataID === dragTarget.dataID) {
            highlightSelectedMarker(e)
          }
        });
      }
    }

    
    // if(target && target !== null && target._objects && target._objects.length === 2  && isMoved || (target !== null && target._cacheCanvas !== null)){
    //   var cCoords = getCoords(imageObj);

    //   var inBounds = insideBoundry({ x: target.aCoords.br.x , y: target.aCoords.br.y }, cCoords);

    //   if (inBounds) {
    //     target.setCoords();
    //     target.saveState();
    //   } else {
    //     target.left = target._stateProperties.left;
    //     target.top = target._stateProperties.top;
    //     return;
    //   }
    // }


    if(selectedShape !== "" && id === "CameraCanvas"  && !isMoved && target && target.id !== "mask" && !addPointToggle && !addTestPointToggle){
      var maskSize = 0;
      if(maskShapes && maskShapes[currentCameraIndex] && maskShapes[currentCameraIndex] !== undefined){
        maskSize = maskShapes[currentCameraIndex].length;
      }

      let width = 100;
      let height = 200;
      if(selectedShape === "Square"){
        width = 100;
        height = 100;
      }

      var scale;
      if(zoom > 1){
        scale =  1 / zoom;
      }
      else{
        scale =  1 * zoom;
      }   

      rectangle = new fabric.Rect({
        left : mouseXPos,
        top:mouseYPos,
        width: width,
        height: height,
        fill: '#2d4f59',
        stroke: '#2c4c54',
        strokeWidth: 1,
        id: "mask",
        dataID: maskSize,               
      });
      
      canvas.add(rectangle);
      canvas.setActiveObject(rectangle); // Important to avoid multiple objects at a time
      canvas.discardActiveObject();
      selectedShape = "";
      var xyValues = getShapeCoordinates(rectangle);
      
      // Calculate the scaling
      rectangle.scaleX = scale;
      rectangle.scaleY = scale;

      if(maskShapes && maskShapes[currentCameraIndex] && maskShapes[currentCameraIndex] !== undefined){
        maskShapes[currentCameraIndex].push(xyValues);
      }
      else{
        if(!maskShapes){
          maskShapes = []
          maskShapes[currentCameraIndex] = [];
          maskShapes[currentCameraIndex][0] = xyValues;
        }
        else{
          maskShapes[currentCameraIndex] = [];
          maskShapes[currentCameraIndex][0] = xyValues;
        }
      }
      updateShapes(maskShapes); 
    }

    if(!dragTarget && isMoved){
      return;
    }
     if(dragTarget && dragTarget.id !== undefined && dragTarget.id === 'test-point' && isMoved){
      return;
    }   
   
    // Scaling in fabric
    let containerHeight = canvas.height;
    let imgHeight = imageObj.height;
    let imgScaleX = imageObj.scaleX;
    let imgScaleY = imageObj.scaleY;
    var actualX ;
    var actualY ;
  
    if(imgHeight > containerHeight){
      if(imgScaleX < 1){
        actualX =  mouseXPos / imgScaleX;
      }
      else{
        actualX =  mouseXPos * imgScaleX;      
      }

      if(imgScaleY < 1){
        actualY =   mouseYPos / imgScaleY;
        containerHeight = containerHeight / imgScaleY;
      }
      else {
        actualY = mouseYPos * imgScaleY;
        containerHeight = containerHeight * imgScaleY;
      }
    }
    else{
      if(imgScaleX < 1){
        actualX =  mouseXPos * imgScaleX;
      }
      else{
        actualX =  mouseXPos / imgScaleX;      
      }

      if(imgScaleY < 1){
        actualY =   mouseYPos * imgScaleY;
        containerHeight = containerHeight * imgScaleY;
      }
      else {
        actualY = mouseYPos / imgScaleY;
        containerHeight = containerHeight / imgScaleY;
      }
    }

    actualY = id != "CameraCanvas" ? actualY : (imageObj.height - actualY);

    if(viewPosition !== "" && viewPosition !== undefined){
      console.log("viewPosition", viewPosition);
    
      var left = viewPosition.tl.x / imgScaleX ;
      var top = viewPosition.tl.y / imgScaleY;

      var scale = 1;
      if(zoom > 1){
        scale =  1 / zoom;
      }
      else{
        scale =  1 * zoom;
      }

      if(zoom !== 1){
        if(scale > 1){
          left = left / scale;
          top = top / scale;
        }
        else
        {
          left = left * scale;
          top = top * scale;
        }
      }

      actualX -= left;
      actualY += Math.abs(top);
    }

    console.log(target)
       
    // Test Point Move operataion
    if(lastDraggedObject && lastDraggedObject !== undefined && lastDraggedObject._objects.length === 2 && lastDraggedObject.id === target.id && target.id === "test-point"){
      if(LastDragTargetXPos !== mouseXPos || LastDragTargetYPos !== mouseYPos){
        updateSelectedTestMarker("", "");
       
        let targetId = lastDraggedObject.dataID;
        testMarkers[currentCameraIndex][targetId]['Mouse_XPos'] = mouseXPos;
        testMarkers[currentCameraIndex][targetId]['Mouse_YPos'] = mouseYPos;
        testMarkers[currentCameraIndex][targetId]['XPos'] = actualX;
        
        if(imgHeight > containerHeight){
          testMarkers[currentCameraIndex][targetId]['YPos']  =  Math.abs(containerHeight - actualY);
        }
        else{
          testMarkers[currentCameraIndex][targetId]['YPos']  =  Math.abs(imgHeight - actualY);
        }
      
        var test_point_type;
        if(id === "Canvas")
          test_point_type = "camera";
        else
          test_point_type = "world";
  
        let testPoint = [];
        testPoint[0] = actualX;
        testPoint[1] = Math.abs(actualY - imgHeight);
  
        updateTestPointAPI(testPoint, test_point_type, testMarkers[currentCameraIndex][targetId], targetId);
        lastDraggedObject = null;
        return;
      }
    }

  
    if(lastDraggedObject && lastDraggedObject !== undefined && lastDraggedObject._objects.length === 2 && lastDraggedObject.id === target.id && target.id !== "test-point" && target.id !== "api-test-point"){
      if(LastDragTargetXPos !== mouseXPos || LastDragTargetYPos !== mouseYPos){
        updateSelectedMarker("","");
        Markers[currentCameraIndex][lastDraggedObject.id]['Mouse_XPos'] = mouseXPos;
        Markers[currentCameraIndex][lastDraggedObject.id]['Mouse_YPos'] = mouseYPos;
        Markers[currentCameraIndex][lastDraggedObject.id]['XPos'] = actualX;
        
        if(imgHeight > containerHeight){
          Markers[currentCameraIndex][lastDraggedObject.id]['YPos']  =  Math.abs(containerHeight - actualY);
        }
        else{
          Markers[currentCameraIndex][lastDraggedObject.id]['YPos']  =  Math.abs(imgHeight - actualY);
        }
        updateMarkers(Markers, id);
        lastDraggedObject = null;
        return;
      }
    }   

    const eCameraToggle = localStorage.getItem('editCameraPositionToggle')
    if (eCameraToggle !== "null" && eCameraToggle !== 'undefined' && eCameraToggle && id === "Canvas" && options.target !== null) {
      console.log("FILTER: Add Camera Markers");
      addCameraMarkers(options);
    }
    else if(addTestPointToggle && homography && homography[currentCameraIndex] && homography[currentCameraIndex] !== undefined && (!lastDraggedObject || (lastDraggedObject && lastDraggedObject !== undefined && lastDraggedObject._objects.length === 2 && lastDraggedObject.id === target.id))){// TODO
      addTestPoints(options);
      console.log("FILTER: Add Test Points");
    }
    else if(!selectAndMovePointToggle){
      if(lastDraggedObject && lastDraggedObject !== undefined && lastDraggedObject._objects.length === 2 && lastDraggedObject.id === target.id){       
        if((LastDragTargetXPos === mouseXPos) && (LastDragTargetYPos === mouseYPos) && target.id !== "test-point"){
          console.log("FILTER: Select and move toggle filter");
          addMarkers(options);
        }
      }
      else{
        console.log("FILTER: Adding markers in the edit camera position");
        addMarkers(options);
      }
      lastDraggedObject = null;
    }
    
  });
    
  let lastClientX;
  let lastClientY;
  var drag = false;
  var dragged = false;
  let lastDraggedObject;
  let LastDragTargetXPos;
  let LastDragTargetYPos;

  canvas.on('mouse:down', function(e) {
    let target = e.target;    
    dragged = false; // important
    drag = false; // important
    
    if(!target || target === undefined){
      return; // important
    }
    
    if(target && target.type === "group"){
      lastDraggedObject = target;
      var p = canvas.getPointer(e.e);
      LastDragTargetXPos = (p.x < 0 ) ? 0.00 : p.x;
      LastDragTargetYPos = (p.y < 0 ) ? 0.00 : p.y;
    }
  
    if(!selectAndMovePointToggle && lastDraggedObject && lastDraggedObject !== undefined && lastDraggedObject._objects.length === 2 && lastDraggedObject.id === target.id && target.id !== "test-point"){       
      return;
    }
    if(target && target.type === "group"  && !selectAndMovePointToggle ){ // TODO condition
      return; // important - Select and move condition.
    }
    
    if(target && target.type === "image"  && !adjustViewPositionToggle && isMoved){
      return; // important - Adjust View position fix
    }

    isMoved = false;
    
    if (target.id === 'dummy'){
      updateSelectedMarker("","");
      updateSelectedTestMarker("", "");
      return;    
    }

    if(e.target.type === 'group' && selectAndMovePointToggle  && e.target.id === "test-point") {
      drag = true;
      dragTarget = e.target;  

      var objs = canvas.getObjects();
      if (objs.length) {
        objs.forEach(function(e) {
          if (e && e.id === 'dummy'){
            canvas.remove(e);
          }
        });
      }

      updateSelectedTestMarker(dragTarget.dataID, id);
      return; // important
    }

    if(e.target.type === 'group' && selectAndMovePointToggle && e.target.id !== "test-point" && e.target.id !== "api-test-point") {
      drag = true;
      dragTarget = e.target;  

      var objs = canvas.getObjects();
      if (objs.length) {
        objs.forEach(function(e) {
          if (e && e.id === 'dummy'){
            canvas.remove(e);
          }
        });
      }

      updateSelectedMarker(dragTarget.id, id);
      return; // important
    }
    else if (e.target.id === 'mask'){
      if(selectAndMovePointToggle){
        e.target.lockMovementX = true;
        e.target.lockMovementY = true;
      }
      return; // important
    }

    if(e.target && e.target.type === "group"  && selectAndMovePointToggle){
      panning = true;
    }
    if(e.target && e.target.type === "image"  && adjustViewPositionToggle){
      panning = true;
      isMoved = false;
      lastClientX = e.e.clientX;
      lastClientY = e.e.clientY;
      var canvasMovedPonts = canvas.getPointer(e.e);
      return; // important - Adjust View position fix
    }
  });
  function getCoords(rect) {
    var coords = []
    coords.push(rect.aCoords.tl);
    coords.push(rect.aCoords.tr);
    coords.push(rect.aCoords.br);
    coords.push(rect.aCoords.bl);
    return coords;
}

function insideBoundry(p, vs) {
  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i].x, yi = vs[i].y;
      var xj = vs[j].x, yj = vs[j].y;
      var intersect = yi > p.y !== yj > p.y && p.x < ((xj - xi) * (p.y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
  }
  return inside;
}

canvas.on('mouse:move', function(e) {
  let target = e.target;
  if(!e.target || e.target.type === undefined){
    return;
  }
  if(!target || target === undefined){
    return; // important
  }

  if(!selectAndMovePointToggle && lastDraggedObject && lastDraggedObject !== undefined && lastDraggedObject._objects.length === 2 && lastDraggedObject.id === target.id && target.id !== "test-point"){       
    return;
  }
  if(target && target.type === "group"  && !selectAndMovePointToggle){ // TODO condition
    if(drag) dragged = true;
    return; // important - Select and move condition.
  }
  else if(target && target.type === "image"  && !adjustViewPositionToggle && isMoved){
    return; // important - Adjust View position fix
  }


  if (panning && e && e.e  && e.target.type === "image") {  
      isMoved = true;      
      // Calculate deltas
      let deltaX = 0;
      let deltaY = 0;
      if (lastClientX) {
        deltaX = e.e.clientX - lastClientX;
      }
      if (lastClientY) {
        deltaY = e.e.clientY - lastClientY;
      }
      // Update the last X and Y values
      lastClientX = e.e.clientX;
      lastClientY = e.e.clientY;

      let delta = new fabric.Point(deltaX, deltaY);
    canvas.relativePan(delta);
  }
});


  var zoom = 1;
  function setZoomScaling (drawGridline) {
    console.log("FILTER: Set Zoom Scaling....");

    if((zoomCanvasId === "canvas" && id === "Canvas") || (zoomCanvasId === "cameraCanvas" && id === "Canvas" && zoomScale !== 1)){
      zoom = zoomScale;
    }
    
    else if((zoomCanvasId === "cameraCanvas" && id === "CameraCanvas") || (zoomCanvasId === "canvas" && id === "CameraCanvas" && cameraZoomScale !== 1)){
      zoom = cameraZoomScale;
    }

    setAdjustViewPosition();
    loadShapes();
    if(drawGridline){
      setTimeout(function(){ drawGridlines() }, 1200);
      setTimeout(function(){ setOtherInitialMarkers() }, 1300);
      setTimeout(function(){ setCameraMarkers() }, 1400);
      setTimeout(function(){ apiTestPoints() }, 1500);
      setTimeout(function(){ addedTestPoints() }, 1600);
    }
    else{
      if(zoom !== 1){
        console.log("Setting markers with zoom")
        setTimeout(function(){ setOtherInitialMarkers() }, 100);
        setTimeout(function(){ setCameraMarkers() }, 100);
        setTimeout(function(){ apiTestPoints() }, 100);
        setTimeout(function(){ addedTestPoints() }, 100);
      }
      else{
        console.log("Setting markers without zoom")
        setOtherInitialMarkers();
        setCameraMarkers();
        apiTestPoints();
        addedTestPoints();
      }
    }

    canvas.setZoom(zoom); // Important - Fix
    canvas.renderAll();
    
    setAdjustedViewPosition(id, imageObj.lineCoords); // Important - Fix
  }

  function drawGridlines() {
    console.log("FILTER: Draw gridlines....");

    if(homography && homography !== undefined &&  homography[currentCameraIndex] !== undefined && plotGridLines ){
      if(id === "Canvas"){
        let imgScaleX = imageObj.scaleX;
        let imgScaleY = imageObj.scaleY;

        var homographyData = homography[currentCameraIndex];
        let world_grid = homographyData['grid_lines']['world_grid'];
        let world_grid_size = world_grid.length;
        let imgHeight = imageObj.height;
        if(world_grid_size){

          for(let k = 0; k < world_grid_size; k++){

            let startPoint = world_grid[k][0];
            let startX = startPoint[0];
            let startY = startPoint[1];

            startX = startX * imgScaleX;
            startY = Math.abs(startY - imgHeight) * imgScaleY;
            let EndPoint = world_grid[k][1];
            let endX = EndPoint[0];
            let endY = EndPoint[1];
            endX = endX * imgScaleX;
            endY = Math.abs(endY - imgHeight) * imgScaleY;

            let strokeWidth = 2;
            var scale;
            if(zoom > 1){
              scale =  1 / zoom;
              strokeWidth = strokeWidth / zoom;
            }
            else{
              scale =  1 * zoom;
            }

            if(viewPosition !== "" && viewPosition !== undefined){
              console.log("viewPosition", viewPosition);
             
              var left = viewPosition.tl.x ;
              var top = viewPosition.tl.y ;             
        
              if(zoom !== 1){
                if(scale > 1){
                  left = left / scale;
                  top = top / scale;
                }
                else
                {
                  left = left * scale;
                  top = top * scale;
                }
              }
              
              startX += left;
              endX += left;
              startY -= Math.abs(top);
              endY -= Math.abs(top);
            }
            console.log("top", top);

            canvas.add(new fabric.Line([startX, startY, endX, endY], {           
              hasControls: false,
              hasBorders: false,
              lockMovementX: true,
              lockMovementY: true,
              hoverCursor: 'default',
              strokeWidth: strokeWidth,
              stroke: '#b0d135'
            }));

            if(k == (world_grid_size-1 )){
              if((homography && homography !== undefined &&  homography[currentCameraIndex] !== undefined && !plotGridLines ) || (!homography && !plotGridLines) || (homography && plotGridLines)){
                setLoadingImageMapping(false);
              }
            }
          }         
        }
      }
      else{
        let imgScaleX = imageObj.scaleX;
        let imgScaleY = imageObj.scaleY;

        var homographyData = homography[currentCameraIndex];
        let camera_grid = homographyData['grid_lines']['camera_grid'];
        let camera_grid_size = camera_grid.length;
        if(camera_grid_size){
          for(let cl = 0; cl < camera_grid_size; cl++){
            let imgHeight = imageObj.height;

            let startPoint = camera_grid[cl][0];
            let startX = startPoint[0];
            let startY = startPoint[1];
            startY = imgHeight - startY;

            console.log("FILTER: StartY", startY);
            startX = startX * imgScaleX;
            startY = Math.abs(startY - imgHeight) * imgScaleY;

            let EndPoint = camera_grid[cl][1];
            let endX = EndPoint[0];
            let endY = EndPoint[1];
            endY = imgHeight - endY;
            endX = endX * imgScaleX;
            endY = Math.abs(endY - imgHeight) * imgScaleY;

            let strokeWidth = 2;
            var scale;
            if(zoom > 1){
              scale =  1 / zoom;
              strokeWidth = strokeWidth / zoom;
            }
            else{
              scale =  1 * zoom;
            }

            if(viewPosition !== "" && viewPosition !== undefined){
              console.log("viewPosition", viewPosition);
             
              var left = viewPosition.tl.x ;
              var top = viewPosition.tl.y ;             
        
              if(zoom !== 1){
                if(scale > 1){
                  left = left / scale;
                  top = top / scale;
                }
                else
                {
                  left = left * scale;
                  top = top * scale;
                }
              }
              
              startX += left;
              endX += left;
              startY -= Math.abs(top);
              endY -= Math.abs(top);
            }
            console.log("top", top);

            canvas.add(new fabric.Line([startX, startY, endX, endY], {
              hasControls: false,
              hasBorders: false,
              lockMovementX: true,
              lockMovementY: true,
              hoverCursor: 'default',
              strokeWidth: strokeWidth,
              stroke: '#b0d135'
            }));

            if(cl == (camera_grid_size-1 )){
              if((homography && homography !== undefined &&  homography[currentCameraIndex] !== undefined && !plotGridLines ) || (!homography && !plotGridLines) || (homography && plotGridLines)){
                setLoadingImageMapping(false);
              }
            }
          }         
        }
      }
    }    
  }


  function setLoadedInitialMarkers (){
    console.log("FILTER: Set loaded initial markers....");

    if(loadedMarkers[currentCameraIndex] && loadedMarkers[currentCameraIndex].length){
      let markerImg = "";
      let markerTextColor = "";

      if(changePointColors){
        markerImg = (id == "Canvas" ? cameraViewPointer : floorPlanPointer);
        markerTextColor = (id == "Canvas" ? "#000" :  "#fff" );
      }
      else {
        markerImg = (id == "Canvas" ? floorPlanPointer : cameraViewPointer);
        markerTextColor = (id == "Canvas" ?  "#fff" : "#000");
      }

     
      let loadMarkerSize = loadedMarkers.length; // Overall project markers

      for(let lmm = 0 ; lmm < loadMarkerSize; lmm++){
        // Marker Looping Started  
        let markerPointers = loadedMarkers[lmm]; // Per scene markers
        let markersSize =  markerPointers.length;
        let lmi = 0;      
        for(let lm = 0 ; lm < markersSize; lm++){
          let mouseXPos = markerPointers[lm][0];
          let mouseYPos = markerPointers[lm][1];        
          
          let actualX = mouseXPos;
          let actualY  = mouseYPos;
          let containerHeight = canvas.height;
          let imgHeight = imageObj.height;
          let imgScaleX = imageObj.scaleX;
          let imgScaleY = imageObj.scaleY;

          //mouseYPos = id == "CameraCanvas" ? imgHeight - mouseYPos : mouseYPos;

          if(imgHeight > containerHeight){
            if(imgScaleX > 1){
              actualX =  mouseXPos / imgScaleX;
            }
            else{
              actualX =  mouseXPos * imgScaleX;      
            }
      
            if(imgScaleY > 1){
              actualY =   mouseYPos / imgScaleY;
            }
            else {
              actualY = mouseYPos * imgScaleY;
            }
          }
          else{
            if(imgScaleX > 1){
              actualX =  mouseXPos * imgScaleX;
            }
            else{
              actualX =  mouseXPos / imgScaleX;      
            }
      
            if(imgScaleY > 1){
              actualY =   mouseYPos * imgScaleY;
            }
            else {
              actualY = mouseYPos / imgScaleY;
            }
          }

          if(viewPosition !== "" && viewPosition !== undefined){
            console.log("viewPosition", viewPosition);
          
            var left = viewPosition.tl.x ;
            var top = viewPosition.tl.y ;
            var scale = 1;
            if(zoom > 1){
              scale =  1 / zoom;
            }
            else{
              scale =  1 * zoom;
            }
      
            if(zoom !== 1){
              if(scale > 1){
                left = left / scale;
                top = top / scale;
              }
              else
              {
                left = left * scale;
                top = top * scale;
              }
            }
            
            actualX += left;
            actualY += Math.abs(top);
          }
          
          if(imgHeight < containerHeight){
            actualY = (imgHeight  * imgScaleY ) - actualY;
          }
          else{
            actualY = (imgHeight  * imgScaleY ) - actualY;
          }

       // actualY = id == "CameraCanvas" ? (imgHeight - actualY) : (imgHeight - actualY)

          actualY = id == "CameraCanvas" ? ((imgHeight * imgScaleY ) - actualY) : actualY;

          let marker_XPos = actualX ;
          let marker_YPos = actualY;

          let marker = Array();
          marker['Mouse_XPos'] = mouseXPos;
          marker['Mouse_YPos'] = mouseYPos;
          marker['XPos'] = mouseXPos;         
          marker['YPos'] =  mouseYPos;
          
          if(Markers[lmm] !== undefined){ // TODO
            Markers[lmm].push(marker);
          }
          else{
            Markers[lmm] = []
            Markers[lmm][0] = marker;
          }

          if(currentCameraIndex === lmm){
            maintainMarkerSize(marker_XPos, marker_YPos, lm , true);
          }

        }
      }    
    }
  }

  function highlightSelectedMarker(e){
    var scale;
    if(zoom > 1){
      scale =  1 / zoom;
    }
    else{
      scale =  1 * zoom;
    }   
    
    fabric.Image.fromURL(pointMarkerDelete, function (img) {
      canvas.add(img);
    }, {
      scaleX: scale, scaleY: scale, left : e.left, top : e.top, hasControls : false, id : "dummy",  selectable: false, hasControls: false
    });
  }
  
  let eventFlag = (selectAndMovePointToggle ? true : false);

  function maintainMarkerSize(marker_XPos, marker_YPos, indexNumber, loadMarkerFlag) {
    let markerImg = "";
    let markerTextColor = "";
    
    if(changePointColors){
      markerImg = (id == "Canvas" ? cameraViewPointer : floorPlanPointer);
      markerTextColor = (id == "Canvas" ? "#000" :  "#fff" );
    }
    else {
      markerImg = (id == "Canvas" ? floorPlanPointer : cameraViewPointer);
      markerTextColor = (id == "Canvas" ?  "#fff" : "#000");
    }
    
    console.log("zoom",zoom);
   
    var scale;
    if(zoom > 1){
      scale =  1 / zoom;
    }
    else{
      scale =  1 * zoom;
    }

    let iconWidth = 12;
    let iconHeight = 30;

    if(zoom === 1){   
      marker_XPos = marker_XPos - iconWidth;
      marker_YPos = marker_YPos - iconHeight;
      scale = 1;
    }
    else{
      if(scale > 1){
        marker_XPos = (marker_XPos) - (iconWidth / scale);
        marker_YPos = (marker_YPos) - (iconHeight  / scale);
      }
      else{
        marker_XPos = (marker_XPos) - (iconWidth * scale);
        marker_YPos = (marker_YPos) - (iconHeight  * scale);
      }        
    }
    
    fabric.Image.fromURL(markerImg, function(img) {
      var txtobj = new fabric.Text((indexNumber < 10) ? '0' + indexNumber.toString() : indexNumber.toString(), function() {});
      txtobj.left = 5 ;
      txtobj.top = 5;
      txtobj.fontSize = 12;
      txtobj.fill =  markerTextColor;

      markerObj = new fabric.Group([img, txtobj], {
        scaleX: scale, scaleY: scale, left: marker_XPos , top: marker_YPos , id: indexNumber, evented: eventFlag
      });
      markerObj.hasControls = markerObj.hasBorders = false;
      canvas.add(markerObj).renderAll();
    });
    canvas.setActiveObject(markerObj);    
  }


  function maintainTestPointMarkerSize(marker_XPos, marker_YPos, indexNumber, id, bgColor, eventFlag) {    
   
    var scale;
    if(zoom > 1){
      scale =  1 / zoom;
    }
    else{
      scale =  1 * zoom;
    }

    let iconWidth = 12;
    let iconHeight = 30;

    if(zoom === 1){   
      marker_XPos = marker_XPos - iconWidth;
      marker_YPos = marker_YPos - iconHeight;
      scale = 1;
    }
    else{
      if(scale > 1){
        marker_XPos = (marker_XPos) - (iconWidth / scale);
        marker_YPos = (marker_YPos) - (iconHeight  / scale);
      }
      else{
        marker_XPos = (marker_XPos) - (iconWidth * scale);
        marker_YPos = (marker_YPos) - (iconHeight  * scale);
      }        
    }

    console.log("FILTER: WRITING POINT TO : ", marker_YPos, "ID ", id);

    var text = (indexNumber !== "") ? (indexNumber < 10) ? '0' + indexNumber.toString() : indexNumber.toString() : "";
    fabric.Image.fromURL(pointMarkerTest, function(img) {
      var txtobj = new fabric.Text(text, function() {});
      txtobj.left = 6 ;
      txtobj.top = 7;
      txtobj.fontSize = 10;
      txtobj.fill =  "#FFFF00";
      txtobj.backgroundColor =  bgColor;

      var testMarkerObj = new fabric.Group([img, txtobj], {
        scaleX: scale, scaleY: scale, left: marker_XPos , top: marker_YPos , id: id, dataID: indexNumber, evented: eventFlag
      });
      testMarkerObj.hasControls = testMarkerObj.hasBorders = false;
      canvas.add(testMarkerObj).renderAll();
    });
  }

  function maintainCameraMarkerSize(marker_XPos, marker_YPos , cameraMarkerImg) {
    var scale;
    if(zoom > 1){
      scale =  1 / zoom;
    }
    else{
      scale =  1 * zoom;
    }

    let iconWidth = (cameraMarkerWidth / 2);
    let iconHeight = (cameraMarkerHeight / 2);

    if(zoom === 1){   
      marker_XPos = marker_XPos - iconWidth;
      marker_YPos = marker_YPos - iconHeight;
      scale = 1;
    }
    else{
      if(scale > 1){
        marker_XPos = (marker_XPos) - (iconWidth / scale);
        marker_YPos = (marker_YPos) - (iconHeight  / scale);
      }
      else{
        marker_XPos = (marker_XPos) - (iconWidth * scale);
        marker_YPos = (marker_YPos) - (iconHeight  * scale);
      }        
    }

    fabric.Image.fromURL(cameraMarkerImg, function (img) {      
      canvas.add(img).renderAll();
    }, {
      left: marker_XPos, top: marker_YPos, scaleX: scale, scaleY: scale, hasControls: false
    });
  }

  function setOtherInitialMarkers (){
    if(Markers[currentCameraIndex] && Markers[currentCameraIndex].length){
      let markerImg = "";
      let markerTextColor = "";

      if(changePointColors){
        markerImg = (id == "Canvas" ? cameraViewPointer : floorPlanPointer);
        markerTextColor = (id == "Canvas" ? "#000" :  "#fff" );
      }
      else {
        markerImg = (id == "Canvas" ? floorPlanPointer : cameraViewPointer);
        markerTextColor = (id == "Canvas" ?  "#fff" : "#000");
      }

      let markerPointers = Markers[currentCameraIndex];
      let markersSize =  markerPointers.length;
     
      for(let j = 0 ; j< markersSize; j++){
        var mouseXPos = markerPointers[j]['XPos'];
        var mouseYPos = markerPointers[j]['YPos'];

        let actualX = mouseXPos;
        let actualY  = mouseYPos;
        let containerHeight = canvas.height;
        let imgHeight = imageObj.height;
        let imgScaleX = imageObj.scaleX;
        let imgScaleY = imageObj.scaleY;
        mouseYPos = id == "CameraCanvas" ? imgHeight - mouseYPos : mouseYPos;
        actualY = id == "CameraCanvas" ? imgHeight - actualY  : actualY;

        console.log("Mouse Y: ", mouseYPos, "Actual Y: ", actualY, "id", id, "imgHeight", imgHeight);

        if(imgHeight > containerHeight){
          if(imgScaleX > 1){
            actualX =  mouseXPos / imgScaleX;
          }
          else{
            actualX =  mouseXPos * imgScaleX;      
          }
    
          if(imgScaleY > 1){
            actualY =   mouseYPos / imgScaleY;
          }
          else {
            actualY = mouseYPos * imgScaleY;
          }
        }
        else{
          if(imgScaleX > 1){
            actualX =  mouseXPos * imgScaleX;
          }
          else{
            actualX =  mouseXPos / imgScaleX;      
          }
    
          if(imgScaleY > 1){
            actualY =   mouseYPos * imgScaleY;
          }
          else {
            actualY = mouseYPos / imgScaleY;
          }
        }

        if(viewPosition !== "" && viewPosition !== undefined){
          console.log("viewPosition", viewPosition);
         
          var left = viewPosition.tl.x ;
          var top = viewPosition.tl.y ;
          var scale = 1;
          if(zoom > 1){
            scale =  1 / zoom;
          }
          else{
            scale =  1 * zoom;
          }
    
          if(zoom !== 1){
            if(scale > 1){
              left = left / scale;
              top = top / scale;
            }
            else
            {
              left = left * scale;
              top = top * scale;
            }
          }
          actualX += left;
          actualY += Math.abs(top);
        }
        //console.log("top", top);
        //console.log("actualY", actualY);
         

        if(imgHeight < containerHeight){
          actualY = (imgHeight  * imgScaleY ) - actualY;
        }
        else{
          actualY = (imgHeight  * imgScaleY ) - actualY;
        }


        let marker_XPos = actualX ;
        let marker_YPos = actualY ;
        maintainMarkerSize(marker_XPos, marker_YPos, j , true);

        if(j == (markersSize-1 )){
          // setZoomScaling();
          if((homography && homography !== undefined &&  homography[currentCameraIndex] !== undefined && !plotGridLines ) || (!homography && !plotGridLines) || (!homography && plotGridLines)){
            
            setLoadingImageMapping(false);

          }
        }
      }
    }
  }

  function addedTestPoints() {
    if(addedTestPoint && addedTestPoint !== undefined && addedTestPoint[currentCameraIndex] !== undefined){
      var testPointLength = addedTestPoint[currentCameraIndex].length;
      let addedPoints = addedTestPoint[currentCameraIndex];
      let tc = 0;
      for(var t = 0; t < testPointLength; t++){
        var mouseXPos = addedPoints[t]['XPos'];
        var mouseYPos = addedPoints[t]['YPos'];
        var actualX = mouseXPos;
        var actualY  = mouseYPos;
        var containerHeight = canvas.height;
        var imgHeight = imageObj.height;
        var imgScaleX = imageObj.scaleX;
        var imgScaleY = imageObj.scaleY;

        if(imgHeight > containerHeight){
          if(imgScaleX > 1){
            actualX =  mouseXPos / imgScaleX;
          }
          else{
            actualX =  mouseXPos * imgScaleX;      
          }
    
          if(imgScaleY > 1){
            actualY =   mouseYPos / imgScaleY;
          }
          else {
            actualY = mouseYPos * imgScaleY;
          }
        }
        else{
          if(imgScaleX > 1){
            actualX =  mouseXPos * imgScaleX;
          }
          else{
            actualX =  mouseXPos / imgScaleX;      
          }
    
          if(imgScaleY > 1){
            actualY =   mouseYPos * imgScaleY;
          }
          else {
            actualY = mouseYPos / imgScaleY;
          }
        }

        console.log("FILTER: In Writing Test Point ", id, "GOT ", actualY, "HEIGHT", imgHeight)

        if(viewPosition !== "" && viewPosition !== undefined){
          //console.log("viewPosition", viewPosition);
         
          var left = viewPosition.tl.x ;
          var top = viewPosition.tl.y ;
          var scale = 1;
          if(zoom > 1){
            scale =  1 / zoom;
          }
          else{
            scale =  1 * zoom;
          }
    
          if(zoom !== 1){
            if(scale > 1){
              left = left / scale;
              top = top / scale;
            }
            else
            {
              left = left * scale;
              top = top * scale;
            }
          }
          
          actualX += left;
          actualY += Math.abs(top);
        }
        console.log("top", top);
        console.log("actualY", actualY);
         

        if(imgHeight < containerHeight){
          actualY = (imgHeight  * imgScaleY ) - actualY;
        }
        else{
          actualY = (imgHeight  * imgScaleY ) - actualY;
        }

        actualY = id == "CameraCanvas" ? (imgHeight * imgScaleY) - actualY : actualY;

        console.log("FILTER: RENDERING MARKER FOR TEST POINT OVER API:", actualY, ":",  imgHeight, ":", id)
        maintainTestPointMarkerSize(actualX, actualY, tc , "test-point", "#000", eventFlag);
        tc++;     
      }
    }
  }

  function apiTestPoints() {
    if(testPoints && testPoints !== undefined && testPoints[currentCameraIndex] !== undefined){
      let apiTestMarkersSize = testPoints[currentCameraIndex].length;
      let tec = 0;
      for(var te = 0; te < apiTestMarkersSize; te++){
        
        let mouseXPos = testPoints[currentCameraIndex][te]['test_point_mapped'][0];
        let mouseYPos = testPoints[currentCameraIndex][te]['test_point_mapped'][1]       
        var actualX = mouseXPos;
        var actualY  = mouseYPos;
        var containerHeight = canvas.height;
        var imgHeight = imageObj.height;
        var imgScaleX = imageObj.scaleX;
        var imgScaleY = imageObj.scaleY;

        console.log("FILTER: GOT BACK FROM API TEST POINT", actualY);
        actualY = id == "CameraCanvas" ? imgHeight - actualY : actualY;
        console.log("FILTER: AFTER INVERSION", actualY);

        if(imgHeight > containerHeight){
          if(imgScaleX > 1){
            actualX =  mouseXPos / imgScaleX;
          }
          else{
            actualX =  mouseXPos * imgScaleX;      
          }
    
          if(imgScaleY > 1){
            actualY =   mouseYPos / imgScaleY;
          }
          else {
            actualY = mouseYPos * imgScaleY;
          }
        }
        else{
          if(imgScaleX > 1){
            actualX =  mouseXPos * imgScaleX;
          }
          else{
            actualX =  mouseXPos / imgScaleX;      
          }
    
          if(imgScaleY > 1){
            actualY =   mouseYPos * imgScaleY;
          }
          else {
            actualY = mouseYPos / imgScaleY;
          }
        }
        console.log("FILTER: GOT BACK FROM API TEST POINT AFTER SCALING", actualY);

        if(viewPosition !== "" && viewPosition !== undefined){
          console.log("viewPosition", viewPosition);
         
          var left = viewPosition.tl.x ;
          var top = viewPosition.tl.y ;
          var scale = 1;
          if(zoom > 1){
            scale =  1 / zoom;
          }
          else{
            scale =  1 * zoom;
          }
    
          if(zoom !== 1){
            if(scale > 1){
              left = left / scale;
              top = top / scale;
            }
            else
            {
              left = left * scale;
              top = top * scale;
            }
          }
          
          actualX += left;
          actualY += Math.abs(top);
        }

        if(imgHeight < containerHeight){
          actualY = (imgHeight  * imgScaleY ) - actualY;
        }
        else{
          actualY = (imgHeight  * imgScaleY ) - actualY;
        }

        actualY = id == "CameraCanvas" ? (imgHeight * imgScaleY) - actualY : actualY;

        console.log("FILTER: RENDERING MARKER FOR TEST POINT OVER API:", actualY, ":",  imgHeight, ":", id)
        maintainTestPointMarkerSize(actualX, actualY, tec , "api-test-point", "#0000FF", false);
        tec++;
      }
    }
  }


  
  function setCameraMarkers (){
    console.log("FILTER: Setting camera markers....");

    // Set Camera Markers
    if(id === "Canvas" && cameraMarkers && cameraMarkers.length) {
      // Marker Looping Started
      if(showAllCameras){
        let camMarkerPointers = cameraMarkers;
        let camMarkersSize =  camMarkerPointers.length;
        for(let c = 0; c < camMarkersSize ; c++ ){
          if(cameraMarkers[c] && cameraMarkers[c].length){
            let cameraMarkerImg = (currentCameraIndex === c) ? redCameraMarker : blueCameraMarker;
            let mouseXPos = camMarkerPointers[c][0]['XPos'];
            let mouseYPos = camMarkerPointers[c][0]['YPos'];
            var actualX = mouseXPos;
            var actualY  = mouseYPos;
            var containerHeight = canvas.height;
            var imgHeight = imageObj.height;
            var imgScaleX = imageObj.scaleX;
            var imgScaleY = imageObj.scaleY;

            mouseYPos = imgHeight - mouseYPos;

            if(imgHeight > containerHeight){
              if(imgScaleX > 1){
                actualX =  mouseXPos / imgScaleX;
              }
              else{
                actualX =  mouseXPos * imgScaleX;      
              }
        
              if(imgScaleY > 1){
                actualY =   mouseYPos / imgScaleY;
              }
              else {
                actualY = mouseYPos * imgScaleY;
              }
            }
            else{
              if(imgScaleX > 1){
                actualX =  mouseXPos * imgScaleX;
              }
              else{
                actualX =  mouseXPos / imgScaleX;      
              }
        
              if(imgScaleY > 1){
                actualY =   mouseYPos * imgScaleY;
              }
              else {
                actualY = mouseYPos / imgScaleY;
              }
            }

            if(viewPosition !== "" && viewPosition !== undefined){
              console.log("viewPosition", viewPosition);
            
              var left = viewPosition.tl.x ;
              var top = viewPosition.tl.y ;
              var scale = 1;
              if(zoom > 1){
                scale =  1 / zoom;
              }
              else{
                scale =  1 * zoom;
              }
        
              if(zoom !== 1){
                if(scale > 1){
                  left = left / scale;
                  top = top / scale;
                }
                else
                {
                  left = left * scale;
                  top = top * scale;
                }
              }
              
              actualX += left;
              actualY += Math.abs(top);
            }
            console.log("top", top);
            console.log("actualY", actualY);
            

            if(imgHeight < containerHeight){
              actualY = (imgHeight  * imgScaleY ) - actualY;
            }
            else{
              actualY = (imgHeight  * imgScaleY ) - actualY;
            }      
            maintainCameraMarkerSize(actualX, actualY, cameraMarkerImg);
          }
        }
      }
      else if(id === "Canvas"  && cameraMarkers && cameraMarkers[currentCameraIndex] !== undefined && cameraMarkers[currentCameraIndex].length){
        let camMarkerPointers = cameraMarkers[currentCameraIndex];
        let mouseXPos = camMarkerPointers[0]['XPos'];
        let mouseYPos = camMarkerPointers[0]['YPos'];
        var actualX = mouseXPos;
        var actualY  = mouseYPos;
        var containerHeight = canvas.height;
        var imgHeight = imageObj.height;
        var imgScaleX = imageObj.scaleX;
        var imgScaleY = imageObj.scaleY;

        if(imgHeight > containerHeight){
          if(imgScaleX > 1){
            actualX =  mouseXPos / imgScaleX;
          }
          else{
            actualX =  mouseXPos * imgScaleX;      
          }
    
          if(imgScaleY > 1){
            actualY =   mouseYPos / imgScaleY;
          }
          else {
            actualY = mouseYPos * imgScaleY;
          }
        }
        else{
          if(imgScaleX > 1){
            actualX =  mouseXPos * imgScaleX;
          }
          else{
            actualX =  mouseXPos / imgScaleX;      
          }
    
          if(imgScaleY > 1){
            actualY =   mouseYPos * imgScaleY;
          }
          else {
            actualY = mouseYPos / imgScaleY;
          }
        }

        //actualY = id == "CameraCanvas" ? imgHeight - actualY : actualY;

        if(viewPosition !== "" && viewPosition !== undefined){
          console.log("viewPosition", viewPosition);
        
          var left = viewPosition.tl.x ;
          var top = viewPosition.tl.y ;
          var scale = 1;
          if(zoom > 1){
            scale =  1 / zoom;
          }
          else{
            scale =  1 * zoom;
          }
    
          if(zoom !== 1){
            if(scale > 1){
              left = left / scale;
              top = top / scale;
            }
            else
            {
              left = left * scale;
              top = top * scale;
            }
          }
          
          actualX += left;
          actualY += Math.abs(top);
        }
        console.log("top", top);
        console.log("actualY", actualY);

        if(imgHeight < containerHeight){
          actualY = (imgHeight  * imgScaleY ) - actualY;
        }
        else{
          actualY = (imgHeight  * imgScaleY ) - actualY;
        }  
        maintainCameraMarkerSize(actualX, actualY, redCameraMarker)
      }
    }
  }

  function loadShapes() {  
    if(maskShapes && maskShapes[currentCameraIndex] && id === "CameraCanvas"){
      if(!maskShapes){
        return;
      }
      var maskSize = 0;
      if(maskShapes && maskShapes[currentCameraIndex] && maskShapes[currentCameraIndex] !== undefined){
        maskSize = maskShapes[currentCameraIndex].length - 1;
      }
      
      for(let shapeIndex = 0; shapeIndex <= maskSize ; shapeIndex++ ){   
        var imgScaleX = imageObj.scaleX;
        var imgScaleY = imageObj.scaleY;
        var containerHeight = canvas.height;
        var imgHeight = imageObj.height;
        console.log("shapeIndex", shapeIndex);  
        var mask = maskShapes[currentCameraIndex][shapeIndex];
        let tl = mask[0];
        let tr = mask[1];
        let bl = mask[2];
        let br = mask[3];

        var width  = tr[0] - tl[0];
        var height = tl[1] - bl[1];
        var mask_left = tl[0];
        var mask_top = tl[1];
       
        var scale = 1;
        if(zoom > 1){
          scale =  1 / zoom;
        }
        else{
          scale =  1 * zoom;
        }

        if(imgHeight > containerHeight){
          if(imgScaleX > 1){
            mask_left =  mask_left / imgScaleX;
            width = width / imgScaleX;
          }
          else{
            mask_left =  mask_left * imgScaleX;      
            width = width * imgScaleX;
          }
    
          if(imgScaleY > 1){
            mask_top =   mask_top / imgScaleY;
            height = height / imgScaleY;
          }
          else {
            mask_top = mask_top * imgScaleY;
            height = height * imgScaleY;
          }
        }
        else{
          if(imgScaleX > 1){
            mask_left =  mask_left * imgScaleX;
            width = width * imgScaleX;
          }
          else{
            mask_left =  mask_left / imgScaleX;    
            width = width / imgScaleX;  
          }
    
          if(imgScaleY > 1){
            mask_top =   mask_top * imgScaleY;
            height = height * imgScaleY;
          }
          else {
            mask_top = mask_top / imgScaleY;
            height = height / imgScaleY;
          }
        }

        if(viewPosition !== "" && viewPosition !== undefined){
          console.log("viewPosition", viewPosition);
        
          var left = viewPosition.tl.x ;
          var top = viewPosition.tl.y ;
         
    
          if(zoom !== 1){
            if(scale > 1){
              left = left / scale;
              top = top / scale;
            }
            else
            {
              left = left * scale;
              top = top * scale;
            }
          }
          
          mask_left += left;
          mask_top += Math.abs(top);
        }
        if(imgHeight < containerHeight){
          mask_top = (imgHeight  * imgScaleY ) - mask_top;
        }
        else{
          mask_top = (imgHeight  * imgScaleY ) - mask_top;
        } 
        rectangle = new fabric.Rect({
          left: mask_left,
          top:  mask_top,
          width: width,
          height: height,
          fill: '#2d4f59',
          stroke: '#2c4c54',
          strokeWidth: 1,
          id: "mask",
          dataID: shapeIndex,
          scaleX: scale,
          scaleY: scale
        });  
        canvas.add(rectangle).renderAll();    
      }
    }
  }
}
