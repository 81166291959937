import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import GreenBtnLg from './../helpers/greenBtnLg/GreenBtnLg';
import './AddScenePopup.css';
import { useState } from "react";
import CustomImageUploader from "./../CreateProject/CustomImageUploaderComponent";
import { useSelector, useDispatch } from 'react-redux';
import { setCameraImageUrls, setloadCameraUrl, setcurrentCameraUrl, setcurrentcameraNotes, setcurrentCameraImageIndex } from './../../actions/imageData';

function AddScenePopup(props) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [singleImageError, setSingleImageError] = useState(false);
    const [editedImage, setEditedImageState] = useState("");
    const [sceneImageValid, setSceneImageValidMessage] = useState("");
    const cameraImageUrls = useSelector(state => state.imageDataReducer.cameraImageUrls);
		let currentCameraNotes = useSelector(state => state.imageDataReducer.currentCameraNotes);
    const getCurrentCameraUrl = useSelector(state => state.imageDataReducer.currentCameraUrl);
    let currentCameraIndex = useSelector(state => state.imageDataReducer.currentCameraIndex);
    const [imageFlag, setImageFlag] = useState(false);
    const [invalidImageError, setInvalidImageError] = useState(false);
    if(currentCameraIndex === undefined)
      currentCameraIndex = 0;
    const [cameraNotes, setTitle] = useState("");
    const dispatch = useDispatch();
    function useForceUpdate() {
      return () => setSceneImageValidMessage(sceneImageValid => ""); // update the state to force render
    }
    useForceUpdate();
    const handleAddButton = () => {
      setInvalidImageError(false);
        if(editedImage.length === 0 ||editedImage === undefined || editedImage === null) {
        setSceneImageValidMessage("Please upload the Scene Image");
      } else {
        setSceneImageValidMessage("");
      let duplicate = false;
      for(var i = 0; i< cameraImageUrls.length; i++){
        let itemSplit = cameraImageUrls[i] .split(";");
        const imgBase64  = itemSplit[2];
        let itemSpl = editedImage .split(";");
        const uniImgBase64  = itemSpl[2];
        if(uniImgBase64 === imgBase64) {
          duplicate = true;
        }
      }

      if(editedImage !== undefined && editedImage !== "" && !duplicate){
				dispatch(setcurrentCameraUrl(editedImage));
				var length = cameraImageUrls.length;
				cameraImageUrls.push(editedImage);
        dispatch(setcurrentCameraImageIndex(length)); 
				dispatch(setloadCameraUrl(true));
				dispatch(setCameraImageUrls(cameraImageUrls));

        // Notes
        if(currentCameraNotes && currentCameraIndex !== undefined ){
          currentCameraNotes[length] = cameraNotes;
          dispatch(setcurrentcameraNotes(currentCameraNotes));
        }
        else {
          let notes = [];
          notes[length] = cameraNotes;
          dispatch(setcurrentcameraNotes(notes));
        }
			}
			props.onHide();
      }
    }

    const setEditedImage = (image) => {
			setEditedImageState(image);
    }

    const closeModal = () => {
			setInvalidImageError(false);
      setSceneImageValidMessage("");
      props.onHide();
		}

    const handleOnload = () => {
      setTitle('');
      setEditedImageState('');
    }
    const handleDeleteImage = (e) => {
      setEditedImageState([]);
      setImageFlag(false);
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={closeModal}
            onShow={handleOnload}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                ADD SCENE
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="edit-scene-body">
                <h2>Add Scene Notes</h2>
                    <p>*Up to 100 characters. This will appear under the scene title.</p>
                       <Form.Control
                        className="text-area"
                        as="textarea"
                        placeholder="This Scene is very important to the Marketing Department. Map is really Tight!"
                        style={{ height: '100px' }}
                        maxlength="100"
												onChange = {event => setTitle(event.target.value)}
                     />
                    <h2>Add Camera View</h2>
                    <p>*Name of the scene will be updated based on the file name.</p>
                    {sceneImageValid && <span className="errorMessage">{sceneImageValid}</span>}
										<CustomImageUploader
                        buttonText="UPLOAD"
                        singleImage={true}
                        singleCameraImage={true}
                        setSingleImageError={setSingleImageError}
                        setErrorMessage={setErrorMessage}
                        withPreview = {true}
                        editScene = {true}
                        cameraImageUrls = {cameraImageUrls}
                        setEditedImage = {setEditedImage}
                        setInvalidImageError={setInvalidImageError}
                    />
                    {(editedImage.length > 0 && editedImage.length) || imageFlag ?
                      <div className="fileUploader ">
                        <div className="fileContainer">
                          <div className="uploadPicturesWrapper">
                            <div className="uploadPicturesWrapper_inner_div">
                              <div className="uploadPictureContainer">
                                <div className="deleteImage" onClick={handleDeleteImage}>X</div>
                                <img src={editedImage} className="uploadPicture" alt="preview" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      : ""
                    }
                    {invalidImageError && <p className="errorMessage">Please upload the valid image. (.png .jpg .jpeg) formats are only allowed.</p>}
                    <GreenBtnLg label="ADD" customClickEvent={handleAddButton}/>
                </div>
            </Modal.Body>
        </Modal>
    );
  }
  
  export default AddScenePopup;