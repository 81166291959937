import React, { Fragment } from "react";
import ImageUploader from "react-images-upload";
import { connect } from 'react-redux';
import { setImageUrls, setCameraImageUrls, setcurrentCameraUrl } from './../../actions/imageData';

class CustomImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: this.props.cameraImageUrls,
      buttonText: this.props.buttonText,
      singleImage: this.props.singleImage,
      singleCameraImage: this.props.singleCameraImage
    };
    
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.props.setErrorMessage("");
    if(pictureFiles.length){
      // uploadPictureContainer
      const { dispatch } = this.props;
      if (this.state.singleImage && !this.state.singleCameraImage) {
        if(typeof(this.props.removeOldImage) === 'function') {
          this.props.removeOldImage();
        }
        console.log(pictureDataURLs);
        dispatch(setImageUrls(pictureDataURLs[0]));
        if(typeof(this.props.setMultipleImageFlag) === 'function'){
          this.props.setMultipleImageFlag(true);
        }
        if(typeof(this.props.handleDeleteImage) === 'function') {
          this.props.handleDeleteImage();
        }
        
        if(typeof(this.props.setMaxImageError) === 'function') {
          this.props.setMaxImageError(false);
        }
      }
      else { // Scene Image
        let uniqueArr = [];
        var existingImgsLenth = 0;
        if(typeof(this.props.setMultipleImageFlag) === 'function'){
          if (this.state.singleImage){ // Over write single and multiple upload
            this.props.setMultipleImageFlag(true);
          }
          else{
            this.props.setMultipleImageFlag(true);
          }
        }

        if(this.props.editScene && typeof(this.props.setEditedImage) === 'function'){
          this.props.setEditedImage(pictureDataURLs[0]);
        }
        else {
          if (this.props.cameraImageUrls !== undefined && this.props.cameraImageUrls.length ) {
            if(!this.props.sceneMultipleFlag && !this.props.singleImage){
              existingImgsLenth = this.props.cameraImageUrls.length;
              pictureDataURLs = this.props.cameraImageUrls.concat(pictureDataURLs);
            }
          }
         
          for(var i = 0; i< pictureDataURLs.length; i++){
            let itemSplit = pictureDataURLs[i] .split(";");
            const imgBase64  = itemSplit[2];
            let duplicate = false;
            for(var j = 0; j< uniqueArr.length; j++){
              let itemSpl = uniqueArr[j] .split(";");
              const uniImgBase64  = itemSpl[2];
              if(uniImgBase64 === imgBase64) {
                duplicate = true;
              }
            }
            if(!duplicate) {
              uniqueArr.push(pictureDataURLs[i]);
            }
          }
          dispatch(setCameraImageUrls(uniqueArr));
        }
        

        let maxImages = 60;
        let uploadedLength = (uniqueArr ? uniqueArr.length : pictureDataURLs.length);
        if (uploadedLength > maxImages) {
          this.props.setMaxImageError(true);
          let extraImages = uploadedLength - maxImages;
          pictureDataURLs.splice(maxImages, extraImages);
        }
        else if(typeof(this.props.setMaxImageError) === 'function') {
          this.props.setMaxImageError(false);
        }
        if(typeof(this.props.removeOldImage) === 'function') {
          this.props.removeOldImage();
        }
        
        if((this.props.sceneMultipleFlag !== this.props.singleImage) || (!this.props.sceneMultipleFlag && !this.props.singleImage)){ 
          if(typeof(this.props.setAddSceneMultipleFlag) === 'function') {
            this.props.setAddSceneMultipleFlag(this.state.singleImage);
          }
        }
      }

      if(typeof(this.props.setInvalidImageError) === 'function'){
        this.props.setInvalidImageError(false);
      }
    }
    else{
      if(this.props.editScene && typeof(this.props.setEditedImage) === 'function'){
        this.props.setEditedImage("");
      }

      if(typeof(this.props.setInvalidImageError) === 'function'){
        this.props.setInvalidImageError(true);
      }
      
      if(typeof(this.props.handleDeleteImage) === 'function') {
        this.props.handleDeleteImage();
      }
    }
  }

  render() {
    return (
      <Fragment>
        <ImageUploader
          withIcon={false}
          withPreview={true}
          buttonText={this.props.buttonText}
          singleImage={this.props.singleImage}
          onChange={this.onDrop}
          imgExtension={[".jpg", ".png", ".jpeg"]}
          singleCameraImage={this.props.singleCameraImage}
          maxFileSize={50242880}
          buttonStyles={{ "backgroundColor": "#0d6efd", "borderColor": "#0d6efd" }}
          removeOldImage = {this.props.removeOldImage}
          setMultipleImageFlag = {this.props.setMultipleImageFlag}
          withPreview = {this.props.withPreview}
          editScene = {this.props.editScene}
          cameraImageUrls = {this.props.cameraImageUrls}
          setEditedImage = {this.props.setEditedImage}
          setInvalidImageError = {this.props.setInvalidImageError}
          errorClass="hideError"
          key = {Date.now()}
          setAddSceneMultipleFlag = {this.props.setAddSceneMultipleFlag}
          sceneMultipleFlag = {this.props.sceneMultipleFlag}
        />
      </Fragment>
    );
  }
}

export default connect()(CustomImageUploader);
