import {
    SET_IMAGE_URLS,
    SET_CAMERA_IMAGE_URLS,
    SET_CREATE_PROJECT_DATA,
    RESET_STATE,
    SET_CURRENT_CAMERA_IMAGE_URL,
    SET_FLOOR_PLAN_MARKERS,
    SET_CAMERA_MARKERS,
    SET_LOAD_CAMERA_URL,
    SET_CAMERA_POSITION_MARKER,
    SET_CURRENT_CAMERA_IMAGE_INDEX,
    SET_CURRENT_CAMERA_NOTES,
    SET_SELECTED_CAMERA_MARKER,
    SET_SELECTED_FLOOR_MARKER,
    SET_HOMOGRAPHY,
    SET_FLOOR_PLAN_TEST_POINT,
    SET_CAMERA_TEST_POINT,
    ADD_FLOOR_PLAN_TEST_POINT,
    ADD_CAMERA_TEST_POINT,
    SET_COUNT_HOMOGRAPHY_CALL,
    GENERATE_TPS,
    SET_MASK_SHAPES,
    SET_SELECTED_CAMERA_TEST_POINT,
    SET_SELECTED_FLOOR_TEST_POINT
} from './../actions/constants';

const initialstate = {
    imageUrls: [],
    cameraImageUrls: [],
    cameraPositionMarker: [],
    project: {
        client_name: null,
        building_name: null,
        project_name: null,
        floorplan1: {},
    },
    floorplanmarkers:[],
    cameramarkers:[],
    currentCameraIndex:0,
    floorPlanAPITestPoints:[],
    cameraAPITestPoints:[],
    floorPlanTestPoints:[],
    cameraTestPoints:[],
    homography:[],
    countHomographyCall:[],
    showTPS: false,
    selectedCameraTestPoint: "",
    selectedFloorTestPoint: "",
    selectedCameraMarker: "",
    selectedFloorMarker: ""
}

function imageDataReducer(state = initialstate, action) {
    switch (action.type) {
        case SET_IMAGE_URLS:
            return { ...state, imageUrls: action.payload };
        case SET_CAMERA_IMAGE_URLS:
            return { ...state, cameraImageUrls: action.payload };
        case SET_CURRENT_CAMERA_IMAGE_URL:
            return { ...state, currentCameraUrl: action.payload };
        case SET_CURRENT_CAMERA_NOTES:
            return { ...state, currentCameraNotes: action.payload };
        case SET_CURRENT_CAMERA_IMAGE_INDEX:
            return { ...state, currentCameraIndex: action.payload };
        case SET_FLOOR_PLAN_MARKERS:
            return { ...state, floorplanmarkers: action.payload };
        case SET_CAMERA_MARKERS:
            return { ...state, cameramarkers: action.payload };
        
        case SET_FLOOR_PLAN_TEST_POINT:
            return { ...state, floorPlanAPITestPoints: action.payload };
        case SET_CAMERA_TEST_POINT:
            return { ...state, cameraAPITestPoints: action.payload };
        case ADD_FLOOR_PLAN_TEST_POINT:
            return { ...state, floorPlanTestPoints: action.payload };
        case ADD_CAMERA_TEST_POINT:
            return { ...state, cameraTestPoints: action.payload };

        case SET_LOAD_CAMERA_URL:
            return { ...state, loadCameraUrl: action.payload };
        case SET_SELECTED_CAMERA_MARKER:
            return { ...state, selectedCameraMarker: action.payload };
        case SET_SELECTED_FLOOR_MARKER:
            return { ...state, selectedFloorMarker: action.payload };
        case SET_SELECTED_CAMERA_TEST_POINT:
            return { ...state, selectedCameraTestPoint: action.payload };
        case SET_SELECTED_FLOOR_TEST_POINT:
            return { ...state, selectedFloorTestPoint: action.payload };
        case SET_CAMERA_POSITION_MARKER:
            return { ...state, cameraPositionMarker: action.payload};
        case SET_CREATE_PROJECT_DATA:
            return {
                ...state,
                project: {
                    ...state.project,
                    client_name: action.payload.client_name,
                    building_name: action.payload.building_name,
                    project_name: action.payload.project_name,
                    cameraData: action.payload.cameraData
                }
            };
        case SET_HOMOGRAPHY:
            return { ...state, homography: action.payload};
        case SET_COUNT_HOMOGRAPHY_CALL:
            return { ...state, countHomographyCall: action.payload };
        case SET_MASK_SHAPES:
            return { ...state, maskShapes: action.payload };
        case GENERATE_TPS:
            return { ...state, showTPS: true };
        case RESET_STATE:
            return { ...initialstate };
        default:
            return state;
    }
}

export default imageDataReducer;