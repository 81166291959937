import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap'
import { Form } from "react-bootstrap";
import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    setGenerateHomography, setGenerateTPS
} from './../../../actions/imageOptions';
import './GenerateProjection.css';
import InfoIcon from "./../../../assets/images/info-message.png";
import { setAdjustViewPosition } from './../../../actions/imageData';

function GenerateProjection(props) {
    const dispatch = useDispatch();
    const currentCameraIndex = useSelector(state => state.imageDataReducer.currentCameraIndex);
    const [item, setItem] = useState({ projection: [] });

    const { projection } = item;

    const handleChange = e => {
        e.persist();

        let newProjection = projection;
        newProjection[currentCameraIndex] = e.target.value;

        setItem(prevState => ({
            ...prevState,
            projection: newProjection
        }));
    };

    const handleContinue = () => {
        dispatch(setAdjustViewPosition(false));
        if (!projection[currentCameraIndex]) {
            return;
        }

        // Close the popup
        props.onHide();

        //Dispatch the redux action according to the value selected
        if (projection[currentCameraIndex] === "Homography") {
            dispatch(setGenerateHomography(true));
        } else {
            // Show TPS
            dispatch(setGenerateTPS(true));
        }
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="generate-projection-mdl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    GENERATE PROJECTION
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="GenerateProjection-body">
                <div className="GenerateProjection">
                    <Form.Check
                        className="projection-radio-button"
                        value="Homography"
                        type="radio"
                        aria-label="Homography"
                        label="Homography"
                        onChange={handleChange}
                        checked={projection[currentCameraIndex] === "Homography"}
                    />
                    <Form.Check
                        className="projection-radio-button"
                        value="TPS"
                        type="radio"
                        aria-label="TPS"
                        label="TPS (Thin plate spline)"
                        onChange={handleChange}
                        checked={projection[currentCameraIndex] === "TPS"}
                    />
                </div>
                {projection[currentCameraIndex] && <div className="generate-projection-footer"> <img src={InfoIcon} alt="" className="info-mess" />Are you sure you want to continue with {projection[currentCameraIndex]}</div>}
                <div className="gp-continue-btn">
                    <Button variant="info" disabled={!projection[currentCameraIndex]} onClick={handleContinue} >CONTINUE</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default GenerateProjection;
