import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, Fragment } from 'react';
import { setHomography } from './../../actions/imageData';
import { generateHomography, generateTPS } from './../../actions/imageOptions';
import { setloadCameraUrl, setCountHomographyCall } from './../../actions/imageData';
import './GenerateHomography.css';

function GenerateHomography(props) {
    const dispatch = useDispatch();
		const countHomographyCall = useSelector(state => state.imageDataReducer.countHomographyCall);
    const floorplanmarkers = useSelector(state => state.imageDataReducer.floorplanmarkers);
    const cameramarkers = useSelector(state => state.imageDataReducer.cameramarkers);
    const generate = useSelector(state => state.imageOptionsReducer.generateHomography);
		const generate_tps = useSelector(state => state.imageOptionsReducer.generateTPS);
    const [flag, setFlag] = useState(false);
		const [errorMessage, setErrorMessage] = useState("");
    const [homographyResult, setHomographyResult] = useState(false);
    const floorPlanImage = useSelector(state => state.imageDataReducer.imageUrls);
    const cameraImageUrls = useSelector(state => state.imageDataReducer.cameraImageUrls);
		let currentCameraIndex = useSelector(state => state.imageDataReducer.currentCameraIndex);
		const homography = useSelector(state => state.imageDataReducer.homography);

		if(currentCameraIndex === undefined)
			currentCameraIndex = 0;

    const [error, setError] = useState('');
    const [responseError, setResponseError] = useState('');

		console.log("floorplanmarkers -*******-",floorplanmarkers[currentCameraIndex]);
		console.log("cameramarkers -*******",cameramarkers[currentCameraIndex]);
		const fetchGenerateHomographyAPI = () => {
			if(floorplanmarkers && cameramarkers && floorplanmarkers[currentCameraIndex] !== undefined  && cameramarkers[currentCameraIndex] !== undefined){
				let floorPoints = floorplanmarkers[currentCameraIndex].map(item=>[item.XPos, item.YPos]);
				let cameraPoints = cameramarkers[currentCameraIndex].map(item=>[item.XPos, item.YPos]);
				let cameraImg = cameraImageUrls[currentCameraIndex];
				const scene_name = cameraImg.split(";")[1].slice(5);
				
				var SceneImage = new Image(); 
				SceneImage.src = cameraImg; 

				var FloorImg = new Image();
				FloorImg.src = floorPlanImage; 

				let data = {
					"cameraData": {
							"world_points": floorPoints,
							"camera_points": cameraPoints,
							"scene_name": scene_name,
					},
					
					// Change these bounds from static to real values
					"camera_bounds": [SceneImage.width, SceneImage.height],
					"world_bounds": [FloorImg.width, FloorImg.height],
				};
				fetch("https://api.pathr.ai/rc/homography-service", {
							mode: "cors",
							method: 'POST',
							headers: {
									'Content-Type': 'application/json'
							},
							body: JSON.stringify(data),
					})
					.then(response => response.json())
						.then(data => {
							setHomographyResult(true);
							// dispatch(generateHomography(false));
							if (data.detail){
									setError(data.detail);
									setResponseError(data.detail);
							}else{
								console.log(data);
								if (homography && homography !== undefined){
									homography[currentCameraIndex] = data;
									homography[currentCameraIndex]["projection_type"] = "Homography";
									dispatch(setHomography(homography));
									console.log("homography", homography);
									dispatch(setloadCameraUrl(true));
									let temp = countHomographyCall;
									if (temp[currentCameraIndex] >= 0) {
										temp[currentCameraIndex] = temp[currentCameraIndex] + 1;
									} else {
										temp[currentCameraIndex] = 0;
									}
									dispatch(setCountHomographyCall(temp));
								}
								else{
									let homographyData = [];
									homographyData[currentCameraIndex] = data;
									homographyData[currentCameraIndex]["projection_type"] = "Homography";
									console.log("homographyData", homographyData);
									dispatch(setHomography(homographyData));
									dispatch(setloadCameraUrl(true));
								}										
							}
							console.log("data", data);
					})
					.catch((error) => {
							setError(error.message);
					});
			}
		}

		const fetchGenerateTPSAPI = () => {
			if(floorplanmarkers && cameramarkers && floorplanmarkers[currentCameraIndex] !== undefined  && cameramarkers[currentCameraIndex] !== undefined){
				let floorPoints = floorplanmarkers[currentCameraIndex].map(item=>[item.XPos, item.YPos]);
				let cameraPoints = cameramarkers[currentCameraIndex].map(item=>[item.XPos, item.YPos]);
				let cameraImg = cameraImageUrls[currentCameraIndex];
				const scene_name = cameraImg.split(";")[1].slice(5);
				
				var SceneImage = new Image(); 
				SceneImage.src = cameraImg; 

				var FloorImg = new Image();
				FloorImg.src = floorPlanImage; 

				let data = {
					"cameraData": {
							"world_points": floorPoints,
							"camera_points": cameraPoints,
							"scene_name": scene_name,
					},
					
					// Change these bounds from static to real values
					"camera_bounds": [SceneImage.width, SceneImage.height],
					"world_bounds": [FloorImg.width, FloorImg.height],
				};
				fetch("https://api.pathr.ai/rc/homography-service/tps/", {
							mode: "cors",
							method: 'POST',
							headers: {
									'Content-Type': 'application/json'
							},
							body: JSON.stringify(data),
					})
					.then(response => response.json())
						.then(data => {
							setHomographyResult(true);
							// dispatch(generateTPS(false));
							if (data.detail){
									setError(data.detail);
									setResponseError(data.detail);
							}else{
								console.log(data);
								if (homography && homography !== undefined){
									homography[currentCameraIndex] = data;
									homography[currentCameraIndex]["projection_type"] = "TPS";
									dispatch(setHomography(homography));
									console.log("homography", homography);
									dispatch(setloadCameraUrl(true));
									let temp = countHomographyCall;
									if (temp[currentCameraIndex] >= 0) {
										temp[currentCameraIndex] = temp[currentCameraIndex] + 1;
									} else {
										temp[currentCameraIndex] = 0;
									}
									dispatch(setCountHomographyCall(temp));
								}
								else{
									let homographyData = [];
									homographyData[currentCameraIndex] = data;									
									homographyData[currentCameraIndex]["projection_type"] = "TPS";
									console.log("homographyData", homographyData);
									dispatch(setHomography(homographyData));
									dispatch(setloadCameraUrl(true));
								}										
							}
							console.log("data", data);
					})
					.catch((error) => {
							setError(error.message);
					});
			}
		}
		
		useEffect(() => {
			console.log("floorplanmarkers --------",floorplanmarkers[currentCameraIndex]);
			console.log("cameramarkers --------",cameramarkers[currentCameraIndex]);
			setError('');		
			setResponseError('');
			setHomographyResult(false);

			if (floorplanmarkers[currentCameraIndex]!== undefined   && cameramarkers[currentCameraIndex]!== undefined ) {
				if(floorplanmarkers[currentCameraIndex].length < 4 || cameramarkers[currentCameraIndex].length < 4){
					setFlag(false);
					setErrorMessage("Not enough points to generate homography, need atleast 4 points on both images to generate homography.");
					return;
				} else if(floorplanmarkers[currentCameraIndex].length !== cameramarkers[currentCameraIndex].length){
					setFlag(false);
					setErrorMessage("Mismatch of number of points in camera and world points.");
					return;
				}
				else{
					setFlag(true);
					setErrorMessage("");
					if(generate){
						fetchGenerateHomographyAPI(); // Homography
					}
					else{
						fetchGenerateTPSAPI(); // TPS
					}					
					return;
				}
			}
			else{
				setFlag(false);
				if(generate){
					setErrorMessage("Not enough points to generate homography, need atleast 4 points on both images to generate homography.");
				}
				else{
					setErrorMessage("Not enough points to generate TPS, need atleast 4 points on both images to generate TPS.");
				}
				
				return;
			}
		},[generate, floorplanmarkers, cameramarkers, generate_tps]);

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {flag ?
                <Modal.Body className="generateHomographyPopup">
                    {error ?
                        <Fragment>
                            <p>{error}</p>
                            <div className="BTN-blue-overlay" onClick={props.onHide}>
                                CLOSE
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            {homographyResult ?
                                <Fragment>
									<p>{responseError ? responseError : ((currentCameraIndex === 0 && countHomographyCall[currentCameraIndex] > 0) || (currentCameraIndex !== 0 && countHomographyCall[currentCameraIndex] > 1)) ? (generate ? "Homography has been updated!" : "TPS has been updated!") : (generate ? "Homography has been created!" : "TPS has been created!")}</p>
                                    <div className="BTN-blue-overlay" onClick={props.onHide}>
                                        CLOSE
                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    <p>{generate ? "Homography" : "TPS"} Generation in Progress</p>
                                    <div className="homography-loader"></div>
                                </Fragment>
                            }
                        </Fragment>
                    }
                </Modal.Body>
                :
                <Modal.Body className="generateHomographyPopup">
                    <p>{errorMessage}</p>
                    <div className="BTN-blue-overlay" onClick={props.onHide}>
                        CLOSE
                    </div>
                </Modal.Body>
            }
        </Modal>
    );
}

export default GenerateHomography;
