import { useSelector, useDispatch } from 'react-redux';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import React from "react";
import {
  showAddPoint,
  showViewPointCoordinates,
  showSaveProject,
  showEditCameraPoint,
  showMovePoint,
  showViewOptions,
  showInfoPopup,
  setGenerateTPS,
  showAddTestPoint,
  hideAddTestPoint,
  setGenerateHomography,
  setSelectedShape,
  setSelectedShapeToDelete,
  hideAddPoint,
  SetImageMappingLoading
} from './../../actions/imageOptions';
import './Footer.css';
import { setSelectedFloorMarker, setSelectedCameraMarker, setSelectedFloorTestPoint, setSelectedCameraTestPoint, setAdjustViewPosition, setloadCameraUrl, addFloorPlanTestPoint, setFloorPlanTestPoint, addCameraTestPoint, setCameraTestPoint, setMaskShapes} from './../../actions/imageData';
import CustomMessagePopup from './../Popups/CustomMessagePopup/CustomMessagePopup';
import GenerateHomography from '../GenerateHomography/GenerateHomography';
import GenerateProjection from '../Popups/GenerateProjection/GenerateProjection';
import { useState, useEffect } from 'react';
import Loader from './../helpers/loader/Loader';

function Footer() {
  const [homographyPopup, setHomographyPopup] = useState(false);
  const [testButtonText, setTestPointButtonText] = useState("Add Test Points");
  const btnToggle = useSelector(state => state.imageBtnOptions);
  const cameraPostionMarker = useSelector(state => state.imageDataReducer.cameraPositionMarker);
  const toggle = useSelector(state => state.imageOptionsReducer);
  const generate = useSelector(state => state.imageOptionsReducer.generateHomography);
  const generate_tps = useSelector(state => state.imageOptionsReducer.generateTPS);
  const addTestPointToggle = useSelector(state => state.imageOptionsReducer.addTestPointToggle);
  const homographyData = useSelector(state => state.imageDataReducer.homography);
  const testPointState = useSelector(state => state.imageDataReducer.testPoint);
  const currentCameraIndex = useSelector(state => state.imageDataReducer.currentCameraIndex);
  const floorPlanAPITestPoints = useSelector(state => state.imageDataReducer.floorPlanAPITestPoints);
  const cameraAPITestPoints = useSelector(state => state.imageDataReducer.cameraAPITestPoints);
  const floorPlanTestPoints = useSelector(state => state.imageDataReducer.floorPlanTestPoints);
  const cameraTestPoints = useSelector(state => state.imageDataReducer.cameraTestPoints);
  const loadCameraUrl = useSelector(state => state.imageDataReducer.loadCameraUrl);  
  const selectedShapeToDelete = useSelector(state => state.imageOptionsReducer.selectedShapeToDelete);
  const maskShapes = useSelector(state => state.imageDataReducer.maskShapes);
  const selectedShape = useSelector(state => state.imageOptionsReducer.selectedShape);
  const imageMappingLoading = useSelector(state => state.imageOptionsReducer.imageMappingLoading);
  const floorplanmarkers = useSelector(state => state.imageDataReducer.floorplanmarkers);
  const cameramarkers = useSelector(state => state.imageDataReducer.cameramarkers);
  const [errorMessage, setErrorMessage] = useState(null);
  const [btnClick, setBtnClick] = useState(false);
  const dispatch = useDispatch();

  let waitingForCameraLoop = (cameramarkers && cameramarkers[currentCameraIndex]!== undefined ) ?cameramarkers[currentCameraIndex].length : 0;
  let waitingForFloorLoop = (floorplanmarkers && floorplanmarkers[currentCameraIndex] !== undefined ) ? floorplanmarkers[currentCameraIndex].length: 0; 

  useEffect(() => {
    let Footer = document.querySelector(".Footer");
    if (window.screen.width < 1300 && btnToggle) {
      Footer.style.height = "105px";
    }
    else {
      Footer.style.height = "65px";
    }
  });

  if (currentCameraIndex === undefined)
    currentCameraIndex = 0;

  const addPointClick = () => {
    setBtnClick(true)
    dispatch(SetImageMappingLoading(true));
    dispatch(setSelectedShape(""));
    dispatch(showAddPoint());
    dispatch(setAdjustViewPosition(false));
    dispatch(setloadCameraUrl(true));    
    dispatch(setSelectedCameraTestPoint(""));
    dispatch(setSelectedFloorTestPoint(""));
    dispatch(setSelectedCameraMarker(""));
    dispatch(setSelectedFloorMarker(""));
    setBtnClick(false)
  }

  const addTestPointClick = () => {
    dispatch(SetImageMappingLoading(true));
    var flag = false;
    dispatch(setSelectedShape(""));
    if (!addTestPointToggle) {
      dispatch(showAddTestPoint());
    }
    dispatch(setAdjustViewPosition(false));
    dispatch(setloadCameraUrl(true));    
    dispatch(setSelectedCameraTestPoint(""));
    dispatch(setSelectedFloorTestPoint(""));
    dispatch(setSelectedCameraMarker(""));
    dispatch(setSelectedFloorMarker(""));
  }

  const showHomography = () => {
    setBtnClick(true)
    if(waitingForCameraLoop !== 0 || waitingForFloorLoop !== 0){
      return;
    }
    setHomographyPopup(true);
    dispatch(setAdjustViewPosition(false));   

    if (floorPlanAPITestPoints && cameraTestPoints && floorPlanAPITestPoints[currentCameraIndex] && cameraTestPoints[currentCameraIndex]) {
      floorPlanAPITestPoints.splice(currentCameraIndex, 1, []);
      cameraTestPoints.splice(currentCameraIndex, 1, []);
      dispatch(addCameraTestPoint(cameraTestPoints));
      dispatch(setFloorPlanTestPoint(floorPlanAPITestPoints));
    }

    if (cameraAPITestPoints && floorPlanTestPoints && cameraAPITestPoints[currentCameraIndex] && floorPlanTestPoints[currentCameraIndex]) {
      cameraAPITestPoints.splice(currentCameraIndex, 1, []);
      floorPlanTestPoints.splice(currentCameraIndex, 1, []);
      dispatch(setCameraTestPoint(cameraAPITestPoints));
      dispatch(addFloorPlanTestPoint(floorPlanTestPoints));
    }
    dispatch(setloadCameraUrl(true));
    setBtnClick(false)
  }
  const generateAPI = () => {
   
    waitingForCameraLoop = (cameramarkers && cameramarkers[currentCameraIndex]!== undefined ) ? cameramarkers[currentCameraIndex].length : 0;
    waitingForFloorLoop = (floorplanmarkers && floorplanmarkers[currentCameraIndex] !== undefined ) ? floorplanmarkers[currentCameraIndex].length: 0;
     // alert("Some  Co-ordinates are empty. Please enter the valid values.");
     if(waitingForCameraLoop){
      cameramarkers[currentCameraIndex].forEach(element => {
        if(element.XPos === ""){
          element.XPos = 0;
        }
        else if(element.YPos === ""){
          element.YPos = 0;
        }
        waitingForCameraLoop --;
        if(waitingForCameraLoop === 0){
          showHomography();
        }
      });
    }

    if(waitingForFloorLoop){
      floorplanmarkers[currentCameraIndex].forEach(element => {
        if(element.XPos === ""){
          element.XPos = 0;
        }
        else if(element.YPos === ""){
          element.YPos = 0;
        }
        waitingForFloorLoop --;
        if(waitingForFloorLoop === 0){
          showHomography();
        }
      });
    }

    if(waitingForFloorLoop === 0 && waitingForCameraLoop === 0 ){
      showHomography();
    }
  }
  const selectMask = (shape) => {
    setBtnClick(true)
    dispatch(setAdjustViewPosition(false));
    dispatch(hideAddPoint());
    dispatch(hideAddTestPoint());
    dispatch(setSelectedShape(shape));
    dispatch(setloadCameraUrl(true));
    setBtnClick(false)
    console.log(shape);
  }

  const selectandMove = () => {
    dispatch(SetImageMappingLoading(true));
    dispatch(showMovePoint()); 
    dispatch(setAdjustViewPosition(false));
    dispatch(setloadCameraUrl(true));
  }

  const deleteMask = () => {
    setBtnClick(true)
    dispatch(setAdjustViewPosition(false));
    // Remove selected Mask
    console.log("selectedShapeToDelete", selectedShapeToDelete);
    if(maskShapes && maskShapes[currentCameraIndex] && selectedShapeToDelete !== "" && selectedShapeToDelete !== undefined){
      maskShapes[currentCameraIndex].splice(selectedShapeToDelete, 1);
      dispatch(setMaskShapes(maskShapes));
      console.log(maskShapes);
      dispatch(setSelectedShapeToDelete(""));
      dispatch(setloadCameraUrl(true));
      setBtnClick(false)
    }
    
  }

  const handleCursorImage = () => {
    const otherMouseCursor = document.querySelector("#cameraCursor");
		otherMouseCursor.style.display = "none";
  }
  return (
    <div className="Footer">
      <Loader show={(btnClick || imageMappingLoading)}/>
      {(generate) && <GenerateHomography show={generate} onHide={() => dispatch(setGenerateHomography(false))} />}
      {(generate_tps) && <GenerateHomography show={generate_tps} onHide={() => dispatch(setGenerateTPS(false))} />}
      {btnToggle && <GenerateProjection show={homographyPopup} onHide={() => setHomographyPopup(false)} />}
      {btnToggle &&
        <div className="toolbuttongroup">
          <button className={`toolbutton addPoint ${toggle.addPointToggle ? 'activeTool' : ''}`} onClick={addPointClick}>Add Point</button>
          <button className={`toolbutton testPoint ${toggle.addTestPointToggle ? 'activeTool' : ''}`} onClick={addTestPointClick}>{testButtonText}</button>
          <button className={`toolbutton editPoint ${toggle.selectAndMovePointToggle ? 'activeTool' : ''}`} onClick={selectandMove}>Select &amp; Move Point</button>
          <button className={`toolbutton pointCoordinates ${toggle.viewPointCoordinatesToggle ? 'activeTool' : ''}`} onClick={() => dispatch(showViewPointCoordinates())}>View Point Coordinates</button>
          <button className="viewbutton viewOption" onClick={() => dispatch(showViewOptions())}>View Options</button>
          <button className={`toolbutton addCamera ${toggle.editCameraPostionToggle ? 'activeTool' : ''}`} onClick={() => dispatch(showEditCameraPoint())}>{cameraPostionMarker.length > 0 ? "Edit Camera Position" : "Add Camera Marker"}</button>
          <button className="viewbutton generateHomography" onClick={generateAPI}>{homographyData && homographyData.length != 0 && homographyData[currentCameraIndex] && homographyData[currentCameraIndex].projection ? "Update Projection" : "Generate Projection"}</button>
          <DropdownButton id="mask-dropdown" title="Add Mask" drop="up" onClick={handleCursorImage}>
            <Dropdown.Item className="delete-mask mask-text" onClick={deleteMask}>Delete Mask</Dropdown.Item>
            <Dropdown.Item className="rectangle-mask mask-text" onClick={() => selectMask("Rectangle")} value = "Rectangle">Rectangle</Dropdown.Item>
            <Dropdown.Item className="square-mask mask-text" onClick={() => selectMask("Square")} value = "Square">Square</Dropdown.Item>
          </DropdownButton>
        </div>
      }
    </div>
  );
}

export default Footer;