import Modal from 'react-bootstrap/Modal';
import './ViewOptions.css';
import { toggleAllCameras, toggleInvertFloorplanColors, toggleChangePointColors, togglePlotGridLines } from "./../../actions/imageOptions";
import { useSelector, useDispatch } from 'react-redux';
import { setloadCameraUrl, setAdjustViewPosition } from './../../actions/imageData';
import React from "react";

function ViewOptions(props) {
    const dispatch = useDispatch();
    const showAllCameras = useSelector(state => state.imageOptionsReducer.showAllCameras);
    const invertFloorplanColors = useSelector(state => state.imageOptionsReducer.invertFloorplanColors);
    const changePointColors = useSelector(state => state.imageOptionsReducer.changePointColors);
    const plotGridLines = useSelector(state => state.imageOptionsReducer.plotGridLines);
		const imageMappingLoading = useSelector(state => state.imageOptionsReducer.imageMappingLoading);

		console.log("imageMappingLoading ==========", imageMappingLoading);

    const setPlotGridLines = () => {
			if(!imageMappingLoading){
        dispatch(togglePlotGridLines()) ;
        dispatch(setloadCameraUrl(true));
        dispatch(setAdjustViewPosition(false));
			}
    }

		const setInvertFloorplanColors = () => {
			if(!imageMappingLoading){
        dispatch(toggleInvertFloorplanColors()) ;
        dispatch(setloadCameraUrl(true));
        dispatch(setAdjustViewPosition(false));
			}
    }

		const setChangePointColors = () => {
			if(!imageMappingLoading){
				dispatch(toggleChangePointColors());
				dispatch(setloadCameraUrl(true));
                dispatch(setAdjustViewPosition(false));
			}
		}

		const setShowAllCameras = () => {
			if(!imageMappingLoading){
			dispatch(toggleAllCameras());
			dispatch(setloadCameraUrl(true));
            dispatch(setAdjustViewPosition(false));
			}
		}

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    VIEW OPTIONS
                </Modal.Title>
            </Modal.Header>
						{!imageMappingLoading ?
            <Modal.Body className="viewOptions">
                <div className="options-item">
                    <span>SHOW ALL CAMERAS</span>
                    <label className="switch">
                        <input type="checkbox" onClick={setShowAllCameras} checked={showAllCameras} />
                        <span className="slider round"></span>
                    </label>
                </div>
                <div className="options-item">
                    <span>INVERT FLOORPLAN COLORS</span>
                    <label className="switch">
                        <input type="checkbox" onClick={setInvertFloorplanColors} checked={invertFloorplanColors} />
                        <span className="slider round"></span>
                    </label>
                </div>
                <div className="options-item">
                    <span>CHANGE POINT COLORS</span>
                    <label className="switch">
                        <input type="checkbox" onClick={setChangePointColors} checked={changePointColors} />
                        <span className="slider round"></span>
                    </label>
                </div>
                <div className="options-item">
                    <span>PLOT GRIDLINES</span>
                    <label className="switch">
                        <input type="checkbox" onClick={setPlotGridLines} checked={plotGridLines} />
                        <span className="slider round"></span>
                    </label>
                </div>
            </Modal.Body>
						:
						<Modal.Body >
								LOADING
								<div className="homography-loader"></div>
						</Modal.Body>
					}
        </Modal>
    );
}

export default ViewOptions;