import React, { useEffect, useState } from 'react';
import CustomReactPictureAnnotation from "../ImageAnnotationComponent";
import ViewPointCoordinates from "./../ViewPointCoordinates/ViewPointCoordinates";
import EditScene from './../EditScene.js/EditScene';
import ViewOptions from '../ViewOptions/ViewOptions';
import { useSelector, useDispatch } from 'react-redux';
import {
    hideViewPointCoordinates,
    showEditScene,
    hideEditScene,
    hideSaveProject,
    hideViewOptions,
    hideInfoPopup,
    showInfoPopup,
    hideAddScenePopup,
    hideAddPoint,
    hideAddTestPoint,
    hideMovePoint    
} from './../../actions/imageOptions';
import Alert from './../helpers/alert/Alert';
import SaveProjectToHomePopup from './../Popups/SaveProjectToHomePopup/SaveProjectToHomePopup';
import InfoPopup from './../Popups/InfoPopup/InfoPopup';
import './ImageMapping.css';
import CustomMessagePopup from './../Popups/CustomMessagePopup/CustomMessagePopup';
import AddScenePopup from '../AddScenePopup/AddScenePopup';
import { useHistory } from "react-router-dom";
import * as Constants from './../../constants'
import {  setSelectedFloorTestPoint, setSelectedCameraTestPoint, setCameraZoomScale, setZoomScale, setZoomCanvasId, setZoomInToggle, setZoomOutToggle, setAdjustViewPosition, setCameraAdjustViewPosition, setloadCameraUrl, setCameramarkers, setFloorplanmarkers, setSelectedFloorMarker, setSelectedCameraMarker, addFloorPlanTestPoint, setFloorPlanTestPoint, addCameraTestPoint, setCameraTestPoint} from './../../actions/imageData';
import crossHair from './../../assets/images/cursor-crosshairs.svg';
import { Fragment } from 'react';

function ImageMapping() {
    const history = useHistory();
    const [saveProjectToHomePopup, setSaveProjectToHomePopup] = useState(false);
    const [saveProjectError, setSaveProjectError] = useState("");
    const [customMessagePopup , setCustomMessagePopup] = useState(false);
    const addScenePopup = useSelector(state => state.imageOptionsReducer.addScenePopup);
    const viewPointCoordinatesToggle = useSelector(state => state.imageOptionsReducer.viewPointCoordinatesToggle);
    const editSceneToggle = useSelector(state => state.imageOptionsReducer.editSceneToggle);
    const viewOptionsToggle = useSelector(state => state.imageOptionsReducer.viewOptionsToggle);
    const saveProjectToggle = useSelector(state => state.imageOptionsReducer.saveProjectToggle);
    const addPointToggle = useSelector(state => state.imageOptionsReducer.addPointToggle);
    const infoPopupToggle = useSelector(state => state.imageOptionsReducer.infoPopup);
    const selectAndMovePointToggle = useSelector(state => state.imageOptionsReducer.selectAndMovePointToggle);
    const adjustViewPositionToggle = useSelector(state => state.imageOptionsReducer.adjustViewPositionToggle);
    const cameraAdjustViewPositionToggle = useSelector(state => state.imageOptionsReducer.cameraAdjustViewPositionToggle);
    const currentCameraNotes = useSelector(state => state.imageDataReducer.currentCameraNotes);
    const selectedCameraPoint = useSelector(state => state.imageDataReducer.selectedCameraMarker);
    const selectedFloorPoint = useSelector(state => state.imageDataReducer.selectedFloorMarker);
	  const loadCameraUrl = useSelector(state => state.imageDataReducer.loadCameraUrl);
    const generate_tps = useSelector(state => state.imageOptionsReducer.generateTPS);
    const generate = useSelector(state => state.imageOptionsReducer.generateHomography);
    let floorPlanAPITestPoints = useSelector(state => state.imageDataReducer.floorPlanAPITestPoints);
    let cameraAPITestPoints = useSelector(state => state.imageDataReducer.cameraAPITestPoints);
    let floorPlanTestPoints = useSelector(state => state.imageDataReducer.floorPlanTestPoints);
    let cameraTestPoints = useSelector(state => state.imageDataReducer.cameraTestPoints);
    const maskShapes = useSelector(state => state.imageDataReducer.maskShapes);
		const homography = useSelector(state => state.imageDataReducer.homography);
		const zoomScale = useSelector(state => state.imageOptionsReducer.zoomScale);
		const cameraZoomScale = useSelector(state => state.imageOptionsReducer.cameraZoomScale);
    const imageMappingLoading = useSelector(state => state.imageOptionsReducer.imageMappingLoading);
    
    const selectedCameraTestPoint = useSelector(state => state.imageDataReducer.selectedCameraTestPoint);
    const selectedFloorTestPoint = useSelector(state => state.imageDataReducer.selectedFloorTestPoint);
    const plotGridLines = useSelector(state => state.imageOptionsReducer.plotGridLines);

    let selectedPoint = "";
    let selectedPointValue = "";
    let testPointFlag = false;
    if(selectedCameraPoint !== undefined && selectedCameraPoint !== null && selectedCameraPoint !== ""){
      testPointFlag = false;
			selectedPoint = (selectedCameraPoint < 10) ? '0' + selectedCameraPoint.toString() : selectedCameraPoint.toString() ;
			selectedPointValue = selectedCameraPoint;
    }
    else if( selectedFloorPoint !== undefined && selectedFloorPoint !== null && selectedFloorPoint !== ""){
      testPointFlag = false;
			selectedPoint = (selectedFloorPoint < 10) ? '0' + selectedFloorPoint.toString() : selectedFloorPoint.toString() ;
			selectedPointValue = selectedFloorPoint;
    }else if(selectedCameraTestPoint !== undefined && selectedCameraTestPoint !== null && selectedCameraTestPoint !== ""){
      testPointFlag = true;
			selectedPoint = (selectedCameraTestPoint < 10) ? 'Test Point 0' + selectedCameraTestPoint.toString() : 'Test Point ' + selectedCameraTestPoint.toString() ;
			selectedPointValue = selectedCameraTestPoint;
    }
    else if( selectedFloorTestPoint !== undefined && selectedFloorTestPoint !== null && selectedFloorTestPoint !== ""){
      testPointFlag = true;
			selectedPoint = (selectedFloorTestPoint < 10) ? 'Test Point 0' + selectedFloorTestPoint.toString() : 'Test Point ' + selectedFloorTestPoint.toString() ;
			selectedPointValue = selectedFloorTestPoint;
    }

    console.log("Add Point Button Clicked. State:", addPointToggle);
    const dispatch = useDispatch();
    const imageUrls = useSelector(state => state.imageDataReducer.imageUrls);
    const cameraImageUrls = useSelector(state => state.imageDataReducer.cameraImageUrls);
    console.log(cameraImageUrls);
    const getCurrentCameraUrl = useSelector(state => state.imageDataReducer.currentCameraUrl);
    let itemSplit = getCurrentCameraUrl ? getCurrentCameraUrl.split(";") : cameraImageUrls[0].split(";");
    const cameraName  = itemSplit[1].split("name=");
    const projectDetails = useSelector(state => state.imageDataReducer.project);
    const floorplanmarkers = useSelector(state => state.imageDataReducer.floorplanmarkers);
    const cameramarkers = useSelector(state => state.imageDataReducer.cameramarkers);
    let currentCameraIndex = useSelector(state => state.imageDataReducer.currentCameraIndex);
		const zoomCanvasId = useSelector(state => state.imageOptionsReducer.zoomCanvasId);
 
		const [fakeState, setFakeState] = useState(false);
		if(currentCameraIndex === undefined)
      currentCameraIndex = 0;

    useEffect(() => {
    	saveProject();
    },[saveProjectToggle]);

    const customeClickEditScene = () => {
        dispatch(hideEditScene());
        dispatch(setAdjustViewPosition(false));
    }
		
		const setSelectedMarkers = () => {
      console.log("FILTER: Set Selected Markers...");
			console.log(fakeState);
			setFakeState(!fakeState);
      dispatch(setAdjustViewPosition(false));
		}
    
    const setSelectedTestMarkers = () => {
			console.log(fakeState);
			setFakeState(!fakeState);
		}

    const setCameraTestPointsData = (addedPoints, APIPoints) => {
      dispatch(addCameraTestPoint(addedPoints));
      dispatch(setFloorPlanTestPoint(APIPoints));
      dispatch(setloadCameraUrl(true));
      dispatch(setAdjustViewPosition(false));
    }

    const setFloorPlanTestPointsData = (addedPoints, APIPoints) => {
      dispatch(addFloorPlanTestPoint(addedPoints));
      dispatch(setCameraTestPoint(APIPoints));
      dispatch(setloadCameraUrl(true));
      dispatch(setAdjustViewPosition(false));
    }
    
    const updateTestPointAPI = (testPoint, test_point_type, testMarkers, pointerIndex) => {
      console.log("ADDED Test Points", testMarkers);
      let projection_type = (homography && homography[currentCameraIndex]) ? homography[currentCameraIndex]['projection_type']: "";
      var testPoint_API_URL = "";
      if(projection_type === "Homography"){
        testPoint_API_URL = "https://api.pathr.ai/rc/homography-service/testpoint/";
      }
      else if (projection_type === "TPS"){
        testPoint_API_URL = "https://api.pathr.ai/rc/homography-service/tps/testpoint/";
      }
			let data = {
				"test_point": testPoint,
				"test_point_type": (test_point_type === "world") ? "camera" : "world",
				"plotting_model": homography[currentCameraIndex]['plotting_model']
			}

			fetch(testPoint_API_URL, {
				mode: "cors",
				method: 'POST',
				headers: {
						'Content-Type': 'application/json'
				},
				body: JSON.stringify(data),
			})
			.then(response => {
        if(!response.ok) throw new Error(response.status);
        else return response.json();
      })
			.then(data => {        
        if(test_point_type === "camera"){
          cameraAPITestPoints[currentCameraIndex][pointerIndex] = data;
          floorPlanTestPoints[currentCameraIndex][pointerIndex] = testMarkers;
          setFloorPlanTestPointsData(floorPlanTestPoints, cameraAPITestPoints);
        }
        else{
          floorPlanAPITestPoints[currentCameraIndex][pointerIndex] = data;
          cameraTestPoints[currentCameraIndex][pointerIndex] = testMarkers;
          setCameraTestPointsData(cameraTestPoints, floorPlanAPITestPoints);
        }
      });
    }

		const addTestPointAPI = (testPoint, test_point_type, testMarkers) => {
      console.log("ADDED Test Points", testMarkers);
      let projection_type = (homography && homography[currentCameraIndex]) ? homography[currentCameraIndex]['projection_type']: "";
      var testPoint_API_URL = "";
      if(projection_type === "Homography"){
        testPoint_API_URL = "https://api.pathr.ai/rc/homography-service/testpoint/";
      }
      else if (projection_type === "TPS"){
        testPoint_API_URL = "https://api.pathr.ai/rc/homography-service/tps/testpoint/";
      }
			let data = {
				"test_point": testPoint,
				"test_point_type": (test_point_type === "world") ? "camera" : "world",
				"plotting_model": homography[currentCameraIndex]['plotting_model']
			}

			fetch(testPoint_API_URL, {
				mode: "cors",
				method: 'POST',
				headers: {
						'Content-Type': 'application/json'
				},
				body: JSON.stringify(data),
			})
			.then(response => {
        if(!response.ok) throw new Error(response.status);
        else return response.json();
      })
			.then(data => {
        console.log("data", data);
        console.log("floorPlanAPITestPoints", floorPlanAPITestPoints);
        console.log("cameraAPITestPoints", cameraAPITestPoints);
        console.log("floorPlanTestPoints", floorPlanTestPoints);
        console.log("cameraTestPoints", cameraTestPoints);

        // FLOOR PLAN TEST POINTS
        if((cameraAPITestPoints && floorPlanTestPoints )){
          if((cameraAPITestPoints[currentCameraIndex] && floorPlanTestPoints[currentCameraIndex] )){
            if(test_point_type === "camera"){
              cameraAPITestPoints[currentCameraIndex].push(data);
              floorPlanTestPoints[currentCameraIndex].push(testMarkers);
              setFloorPlanTestPointsData(floorPlanTestPoints, cameraAPITestPoints);
            }
          }else{
            if(test_point_type === "camera"){              
              let tempAPITestPoints = cameraAPITestPoints;
              tempAPITestPoints[currentCameraIndex] = [];
              tempAPITestPoints[currentCameraIndex][0] = data;
    
              let addedTempTestPoints = floorPlanTestPoints;
              addedTempTestPoints[currentCameraIndex] = [];
              addedTempTestPoints[currentCameraIndex][0] = testMarkers;  
              setFloorPlanTestPointsData(addedTempTestPoints, tempAPITestPoints);              
            }           
          }   
        }
        else {
          if(test_point_type === "camera"){
            let tempAPITestPoints = [];
            tempAPITestPoints[currentCameraIndex] = [];
            tempAPITestPoints[currentCameraIndex][0] = data;
  
            let addedTempTestPoints = [];
            addedTempTestPoints[currentCameraIndex] = [];
            addedTempTestPoints[currentCameraIndex][0] = testMarkers;
            setFloorPlanTestPointsData(addedTempTestPoints, tempAPITestPoints);
          }
        }

        // CAMERA TEST POINTS
        if((floorPlanAPITestPoints && cameraTestPoints )){
          if((floorPlanAPITestPoints[currentCameraIndex] && cameraTestPoints[currentCameraIndex] )){
            if(test_point_type === "world"){
              floorPlanAPITestPoints[currentCameraIndex].push(data);
              cameraTestPoints[currentCameraIndex].push(testMarkers);
              setCameraTestPointsData(cameraTestPoints, floorPlanAPITestPoints);
            }
          }else{
            if(test_point_type === "world"){
              let tempAPITestPoints = floorPlanAPITestPoints;
              tempAPITestPoints[currentCameraIndex] = [];
              tempAPITestPoints[currentCameraIndex][0] = data;
    
              let addedTempTestPoints = cameraTestPoints;
              addedTempTestPoints[currentCameraIndex] = [];
              addedTempTestPoints[currentCameraIndex][0] = testMarkers;              
              setCameraTestPointsData(addedTempTestPoints, tempAPITestPoints);
            }           
          }   
        }
        else {
          if(test_point_type === "world"){
            let tempAPITestPoints = [];
            tempAPITestPoints[currentCameraIndex] = [];
            tempAPITestPoints[currentCameraIndex][0] = data;

            let addedTempTestPoints = [];
            addedTempTestPoints[currentCameraIndex] = [];
            addedTempTestPoints[currentCameraIndex][0] = testMarkers;
            setCameraTestPointsData(addedTempTestPoints, tempAPITestPoints);
          }              
        }


			})
			.catch((error) => {
					// setError(error.message);
          console.log(error.message);
			});
		}

    const saveProject = () => {
      dispatch(setAdjustViewPosition(false));
    if (saveProjectToggle) {
        var cameraData = [];

        for (const [i, scene] of cameraImageUrls.entries()) {
          console.log(i)
          var fl_markers = [];
          var camera_markers = [];
          if(floorplanmarkers){
            if( floorplanmarkers[i] &&  floorplanmarkers[i] !== undefined){
              fl_markers = floorplanmarkers[i].map(function(val, index){
                console.log(val);
                if(val !== undefined)
                  return [val['XPos'], val['YPos'], 1];
              })
            }
            console.log(fl_markers);
          }
          if(cameramarkers){
            if( cameramarkers[i] &&  cameramarkers[i] !== undefined){
              camera_markers = cameramarkers[i].map(function(val, index){
                console.log(val);
                if(val !== undefined)
                  return [val['XPos'], val['YPos']];
              })
            }
            console.log(camera_markers);
          }

					let notes = (currentCameraNotes && currentCameraNotes[i] !== undefined ) ? currentCameraNotes[i] : "";
          let masks = (maskShapes && maskShapes[i] !== undefined ) ? maskShapes[i] : "";
					cameraData.push({
							'notes': notes,
							"camera_points": camera_markers,
							"world_points": fl_markers,
							"filename": scene,
							"projection_type": 	(homography && homography[i]) ? homography[i]['projection_type']: "",
							"projection_model":	(homography && homography[i]) ? homography[i]['projection_model']: "",
							"projection_error": (homography && homography[i]) ? homography[i]['rmse']: "",
              camera_mask: masks,
              camera_location: []
					});
        }


        var data = {
            client_name: projectDetails.client_name,
            site_name: projectDetails.building_name,
            project_name: projectDetails.project_name,
            floorplan_filename: imageUrls,
            camera_A_filename: cameraImageUrls[0],
            cameraData
        };
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', Constants.POSTURL);
        fetch(Constants.APIURL + "/createFolder",
            {
                mode: 'cors',
                method: "POST",
                body: JSON.stringify(data),
                headers: headers
            })
            .then(function (res) {
                return res.json();
            })
            .then(function (data) {
                if (data.success == "true") {
                    var zip_file_path = Constants.APIURL + "/zipFileData?filename=" + data.projectZipFile + ".zip" //put inside "" your path with file.zip
                    var downloadWindow = window.open(zip_file_path);
                    dispatch(hideSaveProject());
                    setSaveProjectToHomePopup(true);
                    if(downloadWindow && downloadWindow !== undefined){
                      downloadWindow.onbeforeunload = function () {
                        fetch(Constants.APIURL + "/removeSavedZipFile",
                        {
                            mode: 'cors',
                            method: "POST",
                            body: JSON.stringify({"zip_filename" : data.projectZipFile + ".zip"}),
                            headers: headers
                        });
                      }
                    }
                    else{
                      setTimeout(function(){
                        var downloadWindow = window.open(zip_file_path);
                        downloadWindow.onbeforeunload = function () {
                          fetch(Constants.APIURL + "/removeSavedZipFile",
                          {
                              mode: 'cors',
                              method: "POST",
                              body: JSON.stringify({"zip_filename" : data.projectZipFile + ".zip"}),
                              headers: headers
                          });
                        }
                      }, 1000);
                    }
                }
            })
            .catch((error) => {
                setSaveProjectError(error.message);
            });
        }
    }

    const handleDeletePoint = (e) => {
			let point = e.currentTarget.dataset.point;
			if(selectedCameraPoint !== undefined  && selectedCameraPoint !== null && selectedCameraPoint !== ""){
				cameramarkers[currentCameraIndex].splice(point, 1);				
				console.log(cameramarkers);
				setCameramarkers(cameramarkers);
				setFakeState(!fakeState);
			}
			else if( selectedFloorPoint !== undefined && selectedFloorPoint !== null && selectedFloorPoint !== ""){
				floorplanmarkers[currentCameraIndex].splice(point, 1);
				setFloorplanmarkers(floorplanmarkers);
				console.log(floorplanmarkers);
			}
      else if(selectedCameraTestPoint !== undefined && selectedCameraTestPoint !== null && selectedCameraTestPoint !== ""){
        cameraTestPoints[currentCameraIndex].splice(point, 1);
        floorPlanAPITestPoints[currentCameraIndex].splice(point, 1);
      }
      else if( selectedFloorTestPoint !== undefined && selectedFloorTestPoint !== null && selectedFloorTestPoint !== ""){
        floorPlanTestPoints[currentCameraIndex].splice(point, 1);
        cameraAPITestPoints[currentCameraIndex].splice(point, 1);
      }
			dispatch(setloadCameraUrl(true));
      dispatch(setSelectedFloorTestPoint(""));
      dispatch(setSelectedCameraTestPoint(""));      
      dispatch(setSelectedFloorMarker(""));
      dispatch(setSelectedCameraMarker(""));
      dispatch(setAdjustViewPosition(false));
    }

    const adjustViewPosition = (zoomAdjust) => {
      dispatch(hideAddPoint());
      dispatch(hideAddTestPoint());
      dispatch(hideMovePoint());
      if(zoomAdjust === true){
        dispatch(setAdjustViewPosition(false));
        dispatch(setCameraAdjustViewPosition(false));
      }
      else{
        dispatch(setAdjustViewPosition(!adjustViewPositionToggle));
        dispatch(setCameraAdjustViewPosition(false));
      }
			dispatch(setloadCameraUrl(true));
    }
    const cameraAdjustViewPosition = () => {
      console.log("FILTER: Adjusted Camera View Position");
      dispatch(hideAddPoint());
      dispatch(hideAddTestPoint());
      dispatch(hideMovePoint());
			dispatch(setCameraAdjustViewPosition(!cameraAdjustViewPositionToggle));
      dispatch(setAdjustViewPosition(false));
			dispatch(setloadCameraUrl(true));
    }
		
		const setCurrentZoomScale = (zoomCanvasId, delta)=>{
			var scale_factor = 0.999;
			var zoom = 1;
			if(zoomCanvasId === "canvas"){
				zoom = zoomScale;
				zoom *= scale_factor ** delta;
				if (zoom > 20) zoom = 20;
				if (zoom < 0.01) zoom = 0.01;
				dispatch(setZoomScale(zoom));
			}
			else if(zoomCanvasId === "cameraCanvas"){
				zoom = cameraZoomScale;
				zoom *= scale_factor ** delta;
				if (zoom > 20) zoom = 20;
				if (zoom < 0.01) zoom = 0.01;
				dispatch(setCameraZoomScale(zoom));
			}          
		}

		const setZoomIn = (e) => {
			let id = e.target.getAttribute("data-id");
			dispatch(setZoomCanvasId(id));
			setCurrentZoomScale(id, -125);
			dispatch(setZoomInToggle(true));
			dispatch(setZoomOutToggle(false));
			dispatch(setloadCameraUrl(true));
      if(homography && homography !== undefined &&  homography[currentCameraIndex] !== undefined && plotGridLines ){
        setTimeout(function(){ adjustViewPosition(true) },1700);
      }
      else{
        setTimeout(function(){ adjustViewPosition(true) },300);
      }
		}

		const setZoomOut = (e) => {
			let id = e.target.getAttribute("data-id");
      if(id === "canvas" && zoomScale <= 1){
        return;
      }
      else if(id === "cameraCanvas" && cameraZoomScale <= 1){
        return;
      }
			dispatch(setZoomCanvasId(id));
			setCurrentZoomScale(id, 125);
			dispatch(setZoomOutToggle(true));
			dispatch(setZoomInToggle(false));
			dispatch(setloadCameraUrl(true));
      if(homography && homography !== undefined &&  homography[currentCameraIndex] !== undefined && plotGridLines ){
        setTimeout(function(){ adjustViewPosition(true) },1700);
      }
      else{
        setTimeout(function(){ adjustViewPosition(true) },300);
      }
		}

    const handleOnMouseLeave = (e) =>{
        const mouseCursor = document.querySelector(".cursor");
        mouseCursor.style.display = "none";
    }

  const handleOnMouseEnter = (e) =>{
    console.log("selectedCameraPoint", selectedCameraPoint);
    console.log("selectedFloorPoint", selectedFloorPoint);
    if (( selectedFloorPoint !== undefined && selectedFloorPoint !== null && selectedFloorPoint !== "")|| ( selectedFloorTestPoint !== undefined && selectedFloorTestPoint !== null && selectedFloorTestPoint !== "")) {
      // dispatch(setloadCameraUrl(true));
      dispatch(setSelectedFloorTestPoint(""));
      dispatch(setSelectedFloorMarker(""));
    }
  }
  const handleOnMouseEnterFloor = (e) =>{
    console.log("selectedCameraPoint", selectedCameraPoint);
    console.log("selectedFloorPoint", selectedFloorPoint);

     if ((selectedCameraPoint !== undefined && selectedCameraPoint !== null && selectedCameraPoint !== "") || (selectedCameraTestPoint !== undefined && selectedCameraTestPoint !== null && selectedCameraTestPoint !== "")) {
      // dispatch(setloadCameraUrl(true));
      dispatch(setSelectedCameraTestPoint(""));
      dispatch(setSelectedCameraMarker(""));
    }
  }
    
	const handleOnMouseMove = (e) =>{
		const otherMouseCursor = document.querySelector("#floorCursor");
		otherMouseCursor.style.display = "none";

		let mouseCursor = document.querySelector("#cameraCursor");
		mouseCursor.style.display = "initial";
		mouseCursor.style.left = `${e.pageX}px`;
		mouseCursor.style.top = `${e.pageY}px`;
	}

	const handleOnMouseMoveFloor = (e) =>{
		const otherMouseCursor = document.querySelector("#cameraCursor");
		otherMouseCursor.style.display = "none";
		
		let mouseCursor = document.querySelector("#floorCursor");
		mouseCursor.style.display = "initial";
		mouseCursor.style.left = `${e.pageX}px`;
		mouseCursor.style.top = `${e.pageY}px`;
	}
    return (
      <Fragment>
      
        <div className="ImageMapping">
            <InfoPopup show={infoPopupToggle} onHide={() => dispatch(hideInfoPopup())} />
            <CustomMessagePopup show={customMessagePopup} onHide={() => setCustomMessagePopup(false)} label="Zoom works with mouse scroll. Zoom Button will work in Version II" />
            <Alert show={saveProjectToggle} onHide={() => dispatch(hideSaveProject())} errorMessage={saveProjectError} />
            <SaveProjectToHomePopup show={saveProjectToHomePopup} onHide={() => { setSaveProjectToHomePopup(false) }} />
            <AddScenePopup show={addScenePopup} onHide={() => {dispatch(hideAddScenePopup())}} />
            <ViewPointCoordinates show={viewPointCoordinatesToggle} onHide={() => dispatch(hideViewPointCoordinates())} />
            {editSceneToggle && <EditScene show={editSceneToggle} onHide={() => customeClickEditScene()}/>}
            <ViewOptions show={viewOptionsToggle} onHide={() => dispatch(hideViewOptions())} />
            <div className="container-sceneTitle">
		        <span className="sceneTitle">Scene:</span> 
                <div className="sceneName">{cameraName}</div>
                <div className="editScene" onClick={() => dispatch(showEditScene())}></div>
	        </div>
            {currentCameraNotes &&
							<div className="scenNotes">{currentCameraNotes[currentCameraIndex]}</div>
            }
            <p className="scene-description"></p>
            <div className="image-outer-box">
                <div className="image-inner-box">
                    <div className="image-inner-box-header">
                      <h3>FLOOR PLAN</h3>                      
                      <div className="viewbuttongroup">
                        <button className="viewBTN zoomIn" onClick={setZoomIn} data-id = {"canvas"} >Increase Zoom</button>
                        <button className="viewBTN zoomOut" onClick={setZoomOut} data-id = {"canvas"} >Decrease Zoom</button>
                        <button className="viewBTN position" onClick={adjustViewPosition} >Adjust View Position</button>
                      </div>                       
                    </div>
										{(currentCameraNotes || !currentCameraNotes )&&
											<div className="image-mapping-box floorPlanSection" onMouseMove={handleOnMouseMoveFloor} onMouseLeave={handleOnMouseLeave} onMouseEnter={handleOnMouseEnterFloor}>
													<img className="cursor" id = "floorCursor" src={crossHair}/>
													<CustomReactPictureAnnotation id="Canvas" floorPlan={true} currentCameraUrl="" adjustViewPositionToggle = {adjustViewPositionToggle} setSelectedMarkers = {setSelectedMarkers} addTestPointAPI = {addTestPointAPI} updateTestPointAPI = {updateTestPointAPI} setSelectedTestMarkers = {setSelectedTestMarkers} />
											</div>
										}
                </div>
                <div className="image-inner-box">
                    <div className="image-inner-box-header">
                      <h3>SCENE</h3>
                      <div className="viewbuttongroup">
                        <button className="viewBTN zoomIn" onClick={setZoomIn} data-id = {"cameraCanvas"} >Increase Zoom</button>
                        <button className="viewBTN zoomOut" onClick={setZoomOut} data-id = {"cameraCanvas"} >Decrease Zoom</button>
                        <button className="viewBTN position" onClick={cameraAdjustViewPosition} >Adjust View Position</button>
                      </div>
                    </div>
										{(currentCameraNotes || !currentCameraNotes ) &&
											<div className="image-mapping-box cameraCanvasSection" onMouseMove={handleOnMouseMove} onMouseLeave={handleOnMouseLeave} onMouseEnter = {handleOnMouseEnter}>
													<img className="cursor" id = "cameraCursor" src={crossHair} />
													<CustomReactPictureAnnotation id="CameraCanvas" currentCameraUrl={getCurrentCameraUrl ? getCurrentCameraUrl : cameraImageUrls[0]} floorPlan={false} cameraAdjustViewPositionToggle = {cameraAdjustViewPositionToggle} setSelectedMarkers = {setSelectedMarkers} addTestPointAPI = {addTestPointAPI} updateTestPointAPI = {updateTestPointAPI} setSelectedTestMarkers = {setSelectedTestMarkers} />
											</div>
										}
                </div>
            </div>
            {selectAndMovePointToggle && 
                <div className="deletePointBox" >
                    <button className="BTN-green-delete" onClick={handleDeletePoint} data-point = {selectedPointValue} >Delete {!testPointFlag ? "Point" : "" } {selectedPoint}</button>
                </div>
            }
        </div>
      </Fragment>
    );
}
export default ImageMapping;
