import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { resetState } from './../../../actions/index';
import './HomeLinkPopup.css';
import React from "react";

function HomeLinkPopup(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const saveProjectToggle = useSelector(state => state.imageOptionsReducer.saveProjectToggle);

    const handleYes = () => {
        props.onHide();
        dispatch(resetState());
        history.push("/");
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {saveProjectToggle ?
                <Modal.Body className="homeLinkPopup">
                    <p>Save project is in-progress. Please wait.</p>
                    <div className="BTN-blue-overlay" onClick={props.onHide}>
                        CLOSE
                    </div>
                </Modal.Body>
                :
                <Modal.Body className="homeLinkPopup">
                    <p>Are you sure you want to go back and start over ?</p>
                    <div className="BTN-blue-overlay" onClick={handleYes}>
                        Yes, I am sure
                    </div>
                    <div className="BTN-blue-overlay" onClick={props.onHide}>
                        No, cancel
                    </div>
                </Modal.Body>
            }
        </Modal>
    );
}

export default HomeLinkPopup;