import {
    SHOW_ADD_POINT,
    HIDE_ADD_POINT,
    SHOW_ADD_TEST_POINT,
    HIDE_ADD_TEST_POINT,
    SHOW_EDIT_CAMERA_POINT,
    HIDE_EDIT_CAMERA_POINT,
    SHOW_MOVE_POINT,
    HIDE_MOVE_POINT,
    SHOW_VIEW_POINT_COORDINATES,
    HIDE_VIEW_POINT_COORDINATES,
    SHOW_SAVE_PROJECT,
    HIDE_SAVE_PROJECT,
    SHOW_VIEW_OPTIONS,
    HIDE_VIEW_OPTIONS,
    SHOW_EDIT_SCENE,
    HIDE_EDIT_SCENE,
    SHOW_SCENE_LIBRARY,
    HIDE_SCENE_LIBRARY,
    TOGGLE_All_CAMERAS,
    TOGGLE_INVERT_FLOORPLAN_COLORS,
    TOGGLE_POINT_COLORS,
    TOGGLE_PLOT_GRIDLINES,
    IMAGE_MAPPING_LOADING,
    SCENE_MULTIPLE_FLAG,
    SHOW_INFO_POPUP,
    HIDE_INFO_POPUP,
    SHOW_ADD_SCENE_POPUP,
    HIDE_ADD_SCENE_POPUP,
    RESET_STATE,
    ENABLE_ADJUST_VIEW_POSITION,
    ENABLE_CAMERA_ADJUST_VIEW_POSITION,
    GENERATE_HOMOGRAPHY,
    GENERATE_TPS,
    SELECTED_SHAPE,
    SELECTED_SHAPE_TO_DELETE,
    ZOOM_SCALE,
    CAMERA_ZOOM_SCALE,
    FLOOR_VIEW_POSITION,
    CAMERA_VIEW_POSITION,
    ZOOM_IN_TOGGLE,
    ZOOM_OUT_TOGGLE,
    ZOOM_CANVAS_ID
} from './../actions/constants';

const initialstate = {
    addPointToggle: false,
    addTestPointToggle: false,
    editCameraPostionToggle: false,
    selectAndMovePointToggle: false,
    viewPointCoordinatesToggle: false,
    saveProjectToggle: false,
    viewOptionsToggle: false,
    editSceneToggle: false,
    sceneLibrary: false,
    showAllCameras: false,
    invertFloorplanColors: false,
    changePointColors: false,
    plotGridLines: false,
    infoPopup: false,
    addScenePopup: false,
    adjustViewPositionToggle: false,
    cameraAdjustViewPositionToggle: false,
    generateHomography: false,
    generateTPS: false,
    zoomScale: 1,
    cameraZoomScale: 1,
    zoomInToggle: false,
    zoomOutToggle: false,
    imageMappingLoading: false,
    selectedShape: "",
    selectedShapeToDelete: "",
    floorViewPosition: "",
    cameraViewPosition: "",
    sceneMultipleFlag: false
}

let footerState = {
    addPointToggle: false,
    addTestPointToggle: false,
    editCameraPostionToggle: false,
    selectAndMovePointToggle: false,
    viewPointCoordinatesToggle: false,
    saveProjectToggle: false,
    viewOptionsToggle: false,
    zoomInToggle: false,
    zoomOutToggle: false,
    imageMappingLoading: false
}

function imageOptionsReducer(state = initialstate, action) {
    switch (action.type) {
        case SHOW_ADD_POINT:
            return { ...state, ...footerState, addPointToggle: !state.addPointToggle  };
        case HIDE_ADD_POINT:
            return { ...state, addPointToggle: false };
        case SHOW_ADD_TEST_POINT:
            return { ...state, ...footerState, addTestPointToggle: true };
        case HIDE_ADD_TEST_POINT:
            return { ...state, addTestPointToggle: false };
        case SHOW_EDIT_CAMERA_POINT:
            return { ...state, ...footerState, editCameraPostionToggle: true };
        case HIDE_EDIT_CAMERA_POINT:
            return { ...state, editCameraPostionToggle: false };
        case SHOW_MOVE_POINT:
            return { ...state, ...footerState, selectAndMovePointToggle: true };
        case HIDE_MOVE_POINT:
            return { ...state, selectAndMovePointToggle: false };
        case SHOW_VIEW_POINT_COORDINATES:
            return { ...state, ...footerState, viewPointCoordinatesToggle: true };
        case ENABLE_ADJUST_VIEW_POSITION:
            return { ...state, adjustViewPositionToggle: action.payload };
        case ENABLE_CAMERA_ADJUST_VIEW_POSITION:
            return { ...state, cameraAdjustViewPositionToggle: action.payload };
        case ZOOM_SCALE:
            return { ...state, zoomScale: action.payload };
        case CAMERA_ZOOM_SCALE:
            return { ...state, cameraZoomScale: action.payload };
        case FLOOR_VIEW_POSITION:
            return { ...state, floorViewPosition: action.payload };
        case CAMERA_VIEW_POSITION:
            return { ...state, cameraViewPosition: action.payload };
        case ZOOM_IN_TOGGLE:
            return { ...state, zoomInToggle: action.payload };
        case ZOOM_OUT_TOGGLE:
            return { ...state, zoomOutToggle: action.payload };
        case ZOOM_CANVAS_ID:
            return { ...state, zoomCanvasId: action.payload };            
        case HIDE_VIEW_POINT_COORDINATES:
            return { ...state, viewPointCoordinatesToggle: false };
        case SHOW_SAVE_PROJECT:
            return { ...state, ...footerState, saveProjectToggle: true };
        case HIDE_SAVE_PROJECT:
            return { ...state, saveProjectToggle: false };
        case SHOW_VIEW_OPTIONS:
            return { ...state, ...footerState, viewOptionsToggle: true };
        case HIDE_VIEW_OPTIONS:
            return { ...state, viewOptionsToggle: false };
        case SHOW_EDIT_SCENE:
            return { ...state, editSceneToggle: true };
        case HIDE_EDIT_SCENE:
            return { ...state, editSceneToggle: false };
        case SHOW_SCENE_LIBRARY:
            return { ...state, sceneLibrary: true };
        case HIDE_SCENE_LIBRARY:
            return { ...state, sceneLibrary: false };
        case TOGGLE_All_CAMERAS:
            return { ...state, showAllCameras: !state.showAllCameras };
        case TOGGLE_INVERT_FLOORPLAN_COLORS:
            return { ...state, invertFloorplanColors: !state.invertFloorplanColors };
        case TOGGLE_POINT_COLORS:
            return { ...state, changePointColors: !state.changePointColors };
        case TOGGLE_PLOT_GRIDLINES:
            return { ...state, plotGridLines: !state.plotGridLines };
        case IMAGE_MAPPING_LOADING:
            return { ...state, imageMappingLoading:  action.payload };
        case SCENE_MULTIPLE_FLAG:
            return { ...state, sceneMultipleFlag:  action.payload };            
        case SHOW_INFO_POPUP:
            return { ...state, infoPopup: true };
        case HIDE_INFO_POPUP:
            return { ...state, infoPopup: false };
        case SHOW_ADD_SCENE_POPUP:
            return { ...state, addScenePopup: true };
        case HIDE_ADD_SCENE_POPUP:
            return { ...state, addScenePopup: false };
        case GENERATE_HOMOGRAPHY:
            return { ...state, generateHomography: action.payload };
        case GENERATE_TPS:
            return { ...state, generateTPS: action.payload };
        case SELECTED_SHAPE:
            return { ...state, selectedShape: action.payload };
        case SELECTED_SHAPE_TO_DELETE:
            return { ...state, selectedShapeToDelete: action.payload };
        case RESET_STATE:
            return { ...initialstate };
        default:
            return state;
    }
}

export default imageOptionsReducer;