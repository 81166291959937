import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import './ViewPointCoordinates.css';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setFloorplanmarkers, setCameramarkers, setloadCameraUrl, setAdjustViewPosition } from './../../actions/imageData';
import ErrorIcon from './../../assets/icons/icon-alert.svg';

function ViewPointCoordinates(props) {
    const dispatch = useDispatch();
    const homographyData = useSelector(state => state.imageDataReducer.homography);

    // Markers[currentCameraIndex]
    let currentCameraIndex = useSelector(state => state.imageDataReducer.currentCameraIndex);
		const [errorMessage, setErrorMessage] = useState(null);
    if (currentCameraIndex === undefined)
        currentCameraIndex = 0;
    const floorplanmarkers = useSelector(state => state.imageDataReducer.floorplanmarkers);
    const cameramarkers = useSelector(state => state.imageDataReducer.cameramarkers);
    console.log(floorplanmarkers);
    const [floorplanmarkerstate, setFloorPlanData] = useState(floorplanmarkers);
    console.log(floorplanmarkerstate);
    const [cameramarkerstate, setCameraData] = useState(cameramarkers);

		const floorPlanImage = useSelector(state => state.imageDataReducer.imageUrls);
		const cameraImageUrls = useSelector(state => state.imageDataReducer.cameraImageUrls);

		let cameraImg = cameraImageUrls[currentCameraIndex];
		const scene_name = cameraImg.split(";")[1].slice(5);

		var SceneImage = new Image(); 
		SceneImage.src = cameraImg; 

		var FloorImg = new Image();
		FloorImg.src = floorPlanImage; 
				
		let camera_bounds_x = SceneImage.width;
		let camera_bounds_y = SceneImage.height;

		let world_bounds_x = FloorImg.width;
		let world_bounds_y = FloorImg.height;

    useEffect(() => {
        setFloorPlanData(floorplanmarkers);
        setCameraData(cameramarkers);				
    }, [floorplanmarkers, cameramarkers]);

    const handleInput = (e, point_type, index, key) => {
			dispatch(setAdjustViewPosition(false));
			let inputValue = e.target.value;   
			if (point_type === "world_points") {
				if((key === "XPos" && ( inputValue === "" || ((inputValue <= world_bounds_x ))) || (key === "YPos" && (inputValue === "" || (inputValue <= world_bounds_y ))))){
					floorplanmarkers[currentCameraIndex][index][key] = inputValue ;
					dispatch(setFloorplanmarkers(floorplanmarkers));
					setFloorPlanData([...floorplanmarkers]);
					setErrorMessage(null);
				}	
				else{
					setErrorMessage("Modified Floor plan value is not in boundary!");
					return;
				}						
			}
			else if (point_type === "camera_points") {
				if((key === "XPos" && ( inputValue === "" || ((inputValue <= camera_bounds_x ))) || (key === "YPos" && (inputValue === "" || (inputValue <= camera_bounds_y ))))){
					cameramarkers[currentCameraIndex][index][key] = inputValue ;
					dispatch(setCameramarkers(cameramarkers));
					setCameraData([...cameramarkers]);
					setErrorMessage(null);
				}
				else{
					setErrorMessage("Modified camera scene value is not in boundary!");
					return;
				}						
			}
    }

    const handleUpdate = () => {
				var errorFlag = 0;
				if((!cameramarkers[currentCameraIndex] || cameramarkers[currentCameraIndex] === undefined) && (!floorplanmarkers[currentCameraIndex] || floorplanmarkers[currentCameraIndex] === undefined))
					return;
				cameramarkers[currentCameraIndex].forEach(element => {
					if(element.XPos === "" || element.YPos === ""){
						setErrorMessage("Some colums are empty. Please enter the valid value for Co-ordinates.");
						errorFlag = 1;
					}
				});

				floorplanmarkers[currentCameraIndex].forEach(element => {
					if(element.XPos === "" || element.YPos === ""){
						setErrorMessage("Some colums are empty. Please enter the valid value for Co-ordinates.");
						errorFlag = 1;
					}
				});

				if(!errorFlag){
					dispatch(setAdjustViewPosition(false));
					props.onHide();
					dispatch(setloadCameraUrl(true));
				}
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    POINT COORDINATES
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="outer-box">
                    <div className="inner-box">
                        <h2>FLOOR PLAN</h2>
                        <table width="100%">
                            <colgroup>
                                <col span="1" width="100px" />
                                <col span="2" />
                                <col span="1" width="100px" />
                            </colgroup>
                            <tr>
                                <th>POINT ID</th>
                                <th>X</th>
                                <th>Y</th>
                                <th>Z</th>
                            </tr>
                            {floorplanmarkers && floorplanmarkers[currentCameraIndex] &&
                                floorplanmarkers[currentCameraIndex].map((coordinates, index) => {
                                    return (
                                        <tr>
                                            <td>{(index < 10 ? '0' : '') + index}</td>
                                            <td>
                                                <input className="viewCoordInput" type="number" min="0" value={coordinates['XPos']} onChange={(e) => handleInput(e, "world_points", index, 'XPos')} />
                                            </td>
                                            <td>
                                                <input className="viewCoordInput" type="number" min="0" value={coordinates['YPos']} onChange={(e) => handleInput(e, "world_points", index, 'YPos')} />
                                            </td>
                                            <td>1</td>
                                        </tr>
                                    );
                                })
                            }
                        </table>
                    </div>
                    <div className="inner-box">
                        <h2>SCENE</h2>
                        <table width="100%">
                            <colgroup>
                                <col span="1" width="100px" />
                            </colgroup>
                            <tr>
                                <th><span>POINT ID</span></th>
                                <th>X</th>
                                <th>Y</th>
                            </tr>
                            {cameramarkers && cameramarkers[currentCameraIndex] &&
                                cameramarkers[currentCameraIndex].map((coordinates, index) => {
                                    return (
                                        <tr>
                                            <td>{(index < 10 ? '0' : '') + index}</td>
                                            <td>
                                                <input className="viewCoordInput" type="number" min="0" value={coordinates['XPos'].toString()} onChange={(e) => handleInput(e, "camera_points", index, 'XPos')} />
                                            </td>
                                            <td>
                                                <input className="viewCoordInput" type="number" min="0" value={coordinates['YPos'].toString()} onChange={(e) => handleInput(e, "camera_points", index, 'YPos')} />
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </table>
                    </div>
                </div>
                {errorMessage && <p className="errorMessage-pointCoordinates">
                    <img src={ErrorIcon} alt="Error-icon" />
                    {errorMessage}
                </p>}
                {homographyData && homographyData.length!==0 && homographyData !== undefined && homographyData[currentCameraIndex] !== undefined &&
                    <h3 className="error-h3">
                        <img src={ErrorIcon} alt="Error-icon" />
                        <div>Projection Error: {homographyData[currentCameraIndex].rmse}</div>
                    </h3>
                }
                {!errorMessage && <div className="update-btn">
                    <Button variant="primary" onClick={handleUpdate}>UPDATE</Button>
                </div>}
            </Modal.Body>
        </Modal>
    );
}

export default ViewPointCoordinates;

ViewPointCoordinates.propTypes = {
    onHide: PropTypes.func,
};